<template>
    <!-- COMP-102a Call To Action	-->
    <div class="category-cta">
        <div class="cta__item-wrap">
            <button class="cta__link ">
                <span class="cta__link-text">{{ specialty }}</span>
                <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M5.44739 4.55087C5.69533 4.7988 5.69533 5.20145 5.44739 5.44938L1.63915 9.25763C1.39121 9.50556 0.98857 9.50556 0.740637 9.25763C0.492704 9.00969 0.492704 8.60705 0.740637 8.35912L4.10062 4.99913L0.742621 1.63915C0.494688 1.39121 0.494688 0.98857 0.742621 0.740637C0.990553 0.492704 1.3932 0.492704 1.64113 0.740637L5.44938 4.54889L5.44739 4.55087Z" fill="#0072CE"/>
                  <path d="M4.62953 5.4474C4.3816 5.19946 4.3816 4.79682 4.62953 4.54889L8.43778 0.740637C8.68571 0.492705 9.08835 0.492705 9.33629 0.740638C9.58422 0.98857 9.58422 1.39121 9.33629 1.63915L5.9763 4.99913L9.3343 8.35912C9.58224 8.60705 9.58224 9.00969 9.3343 9.25763C9.08637 9.50556 8.68373 9.50556 8.43579 9.25763L4.62755 5.44938L4.62953 5.4474Z" fill="#0072CE"/>
                </svg>
            </button>
        </div>
    </div>
</template>

<script>
  export default {
    name: "CategoryCta",
    data: () => {
      return {
      };
    },
    props: {
      specialty: String
    },
    computed: {
    },
    methods: {
    }
  };
</script>  