var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    !_vm.banner_homepage
      ? _c("div", { staticClass: "locations-tabs__wrapper" }, [
          _c("nav", { staticClass: "locations-tabs" }, [
            _c("div", { staticClass: "locations-tabs__inner" }, [
              _c(
                "ul",
                {
                  staticClass: "tabs",
                  attrs: {
                    id: "",
                    "data-deep-link": "true",
                    "data-update-history": "true",
                    "data-tabs": "ow5629-tabs",
                    role: "tablist"
                  }
                },
                [
                  _c(
                    "li",
                    {
                      staticClass: "tabs-title is-active",
                      attrs: { role: "presentation" }
                    },
                    [
                      _c(
                        "a",
                        {
                          attrs: {
                            href: "/find-care",
                            role: "tab",
                            "aria-controls": "providers-results",
                            "aria-selected": "true",
                            id: "providers-results-label",
                            tabindex: "0"
                          }
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass: "tabs-icon",
                              attrs: {
                                "aria-hidden": "true",
                                role: "presentation"
                              }
                            },
                            [
                              _c(
                                "svg",
                                {
                                  attrs: {
                                    width: "31",
                                    height: "27",
                                    "aria-hidden": "true"
                                  }
                                },
                                [
                                  _c("use", {
                                    attrs: {
                                      "xlink:href":
                                        "/assets/images/static/sprite.svg#find-a-doc"
                                    }
                                  })
                                ]
                              )
                            ]
                          ),
                          _vm._v(
                            "\n\n                            Find a Doctor\n                        "
                          )
                        ]
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "li",
                    {
                      staticClass: "tabs-title",
                      attrs: { role: "presentation" }
                    },
                    [
                      _c(
                        "a",
                        {
                          attrs: {
                            href: "/locations",
                            role: "tab",
                            "aria-controls": "locations-results",
                            "aria-selected": "false",
                            id: "locations-results-label",
                            tabindex: "-1"
                          }
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass: "tabs-icon",
                              attrs: {
                                "aria-hidden": "true",
                                role: "presentation"
                              }
                            },
                            [
                              _c(
                                "svg",
                                {
                                  attrs: {
                                    width: "31",
                                    height: "27",
                                    "aria-hidden": "true"
                                  }
                                },
                                [
                                  _c("use", {
                                    attrs: {
                                      "xlink:href":
                                        "/assets/images/static/sprite.svg#hospital"
                                    }
                                  })
                                ]
                              )
                            ]
                          ),
                          _vm._v(
                            "\n\n                            Find a Location\n                        "
                          )
                        ]
                      )
                    ]
                  )
                ]
              )
            ])
          ])
        ])
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "c-keyboard-search-hero",
        class: { "search-hero-homepage": _vm.banner_homepage }
      },
      [
        _c("div", { staticClass: "c-keyboard-search-hero--content" }, [
          _c(
            "div",
            {
              staticClass:
                "c-keyboard-search-hero--content-wrapper typeahead-container"
            },
            [
              !_vm.banner_homepage
                ? _c("h1", [_vm._v("Find a Doctor")])
                : _vm._e(),
              _vm._v(" "),
              _c("label", [
                _vm._m(0),
                _vm._v(" "),
                _c("div", { staticClass: "input-set" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.search_text,
                        expression: "search_text"
                      }
                    ],
                    ref: "keywordField",
                    staticClass: "form__input",
                    attrs: {
                      type: "search",
                      name: "keyboard-search-hero",
                      id: "keyboard-search-hero",
                      "data-typeahead-type": "keyboard-search-hero",
                      "data-typeahead-min-length": "1",
                      "aria-label":
                        "Enter a Specialty, Condition, or Doctor Name",
                      placeholder: "",
                      autocomplete: "off"
                    },
                    domProps: { value: _vm.search_text },
                    on: {
                      keyup: _vm.onSearchChange,
                      keydown: _vm.handleKeydown,
                      blur: _vm.onFieldLostFocus,
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.search_text = $event.target.value
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "keyboard-search-hero--submit",
                      on: { click: _vm.onSearchClick }
                    },
                    [_vm._v(_vm._s(_vm.bannerButtonTitle))]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "keyboard-search-hero--filter",
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.openFilters.apply(null, arguments)
                        }
                      }
                    },
                    [
                      _vm._v("Sort and Filter "),
                      !_vm.isEmpty(_vm.ActiveFilters) &&
                      _vm.filtersCount(_vm.ActiveFilters) != 0
                        ? _c("span", [
                            _vm._v(
                              "(" +
                                _vm._s(_vm.filtersCount(_vm.ActiveFilters)) +
                                ")"
                            )
                          ])
                        : _vm._e()
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "svg",
                    {
                      staticClass: "icn-search",
                      attrs: {
                        width: "16",
                        height: "16",
                        viewBox: "0 0 16 16",
                        fill: "none",
                        xmlns: "http://www.w3.org/2000/svg"
                      }
                    },
                    [
                      _c("path", {
                        attrs: {
                          d:
                            "M7.24857 0.800781H7.50057C9.43557 0.917781 11.2806 1.91678 12.4146 3.61778C13.9716 5.95778 13.8006 9.00878 12.0456 11.1418L15.2046 14.3008L14.3046 15.2008L11.1456 12.0418C10.0926 12.8968 8.78757 13.4008 7.42857 13.4638H7.13157C4.10757 13.4638 1.50657 11.3308 0.921567 8.36978C0.336567 5.40878 1.92057 2.43878 4.71957 1.28678C5.53857 0.944781 6.40257 0.791781 7.24857 0.809781V0.800781ZM7.14057 2.06978C4.71957 2.06978 2.64057 3.77978 2.17257 6.14678C1.70457 8.51378 2.97356 10.8898 5.20556 11.8168C5.79956 12.0598 6.42057 12.1858 7.03257 12.2038H7.26657C8.87757 12.1678 10.4166 11.3578 11.3616 9.95378C12.7026 7.94678 12.4416 5.26478 10.7316 3.55478C9.77757 2.60078 8.49056 2.06978 7.14956 2.06978H7.14057Z",
                          fill: "#4D4D4D"
                        }
                      })
                    ]
                  )
                ])
              ]),
              _vm._v(" "),
              _vm.searchResults && this.search_text != ""
                ? _c(
                    "div",
                    { ref: "typeaheadContainer", staticClass: "typeahead" },
                    _vm._l(_vm.searchResults, function(array, key, index) {
                      return _c("div", [
                        _c(
                          "h3",
                          {
                            class: {
                              "typeahead__title--first": index === 0,
                              typeahead__title: true
                            }
                          },
                          [_vm._v(_vm._s(_vm.getCategoryFriendly(key)))]
                        ),
                        _vm._v(" "),
                        _c(
                          "ul",
                          _vm._l(array, function(item, index) {
                            return _c(
                              "li",
                              {
                                key: index,
                                class: {
                                  highlighted: _vm.search_text === item.name
                                }
                              },
                              [
                                _c("button", {
                                  attrs: { value: item.name },
                                  domProps: {
                                    innerHTML: _vm._s(item.highlight)
                                  },
                                  on: {
                                    mousedown: function($event) {
                                      $event.preventDefault()
                                      return _vm.onUpdateField(key, item.name)
                                    }
                                  }
                                })
                              ]
                            )
                          }),
                          0
                        )
                      ])
                    }),
                    0
                  )
                : _vm._e()
            ]
          )
        ])
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", [
      _c("b", [_vm._v("Enter a Specialty, Condition, or Doctor Name")])
    ])
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-16259ece", { render: render, staticRenderFns: staticRenderFns })
  }
}