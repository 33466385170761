<template>
  <div>
    <div class="navbar">
      <nav v-if="enable_map_features">
        <a :class="{active : view == 'list'}" href @click.prevent="setView('list')">
          List
          <span class="view-icon">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M21.5628 4.19653H2.45413C1.54927 4.19653 0.816182 3.43639 0.816182 2.49817C0.816182 1.55994 1.54927 0.799805 2.45413 0.799805H21.5628C22.4677 0.799805 23.2008 1.55994 23.2008 2.49817C23.2008 3.43639 22.4677 4.19653 21.5628 4.19653ZM18.8155 11.9998C18.8155 11.0616 18.0824 10.3014 17.1776 10.3014H2.43873C1.53387 10.3014 0.800781 11.0616 0.800781 11.9998C0.800781 12.938 1.53387 13.6982 2.43873 13.6982H17.1776C18.0824 13.6982 18.8155 12.938 18.8155 11.9998ZM23.0237 21.5014C23.0237 20.5632 22.2906 19.8031 21.3858 19.8031H2.43873C1.53387 19.8031 0.800781 20.5632 0.800781 21.5014C0.800781 22.4397 1.53387 23.1998 2.43873 23.1998H21.3858C22.2906 23.1998 23.0237 22.4397 23.0237 21.5014Z" fill="#470A68"/>
            </svg>
          </span>
        </a>
        <a :class="{active : view == 'map'}" href @click.prevent="setView('map')">
          Map
          <span class="view-icon">
            <svg width="30" height="24" viewBox="0 0 30 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M29.0738 0.938903C28.8685 0.798159 28.6063 0.761298 28.3682 0.841723L19.5871 3.81912L10.6645 0.840047C10.6645 0.840047 10.6472 0.835021 10.6386 0.833345C10.6231 0.828319 10.6076 0.824968 10.592 0.819941C10.5765 0.81659 10.5627 0.813239 10.5472 0.811563C10.5316 0.808212 10.5178 0.806537 10.5023 0.804861C10.4868 0.803186 10.4713 0.80151 10.4557 0.80151C10.4402 0.80151 10.4264 0.80151 10.4109 0.80151C10.3954 0.80151 10.3798 0.80151 10.3643 0.803186C10.3488 0.803186 10.335 0.804861 10.3194 0.806537C10.3039 0.808212 10.2884 0.811563 10.2729 0.813239C10.2591 0.81659 10.2435 0.818266 10.2297 0.821617C10.2142 0.824968 10.1987 0.829994 10.1814 0.835021C10.1728 0.838372 10.1642 0.838372 10.1556 0.841723L1.12256 3.90624C0.810308 4.0118 0.601562 4.29664 0.601562 4.61834V22.4458C0.601562 22.6905 0.72405 22.92 0.929345 23.0608C1.06218 23.1529 1.21917 23.1998 1.37961 23.1998C1.46587 23.1998 1.55213 23.1864 1.63494 23.1579L10.4161 20.1805L19.3386 23.1596C19.3386 23.1596 19.3438 23.1596 19.3473 23.1613C19.3749 23.1697 19.4025 23.1764 19.4301 23.1814C19.4422 23.1848 19.4542 23.1881 19.468 23.1898C19.5077 23.1965 19.5474 23.1998 19.5888 23.1998C19.5888 23.1998 19.5888 23.1998 19.5905 23.1998C19.5905 23.1998 19.5905 23.1998 19.5922 23.1998C19.6336 23.1998 19.6733 23.1965 19.7147 23.1898C19.7285 23.1881 19.7406 23.1831 19.7527 23.1814C19.7803 23.1764 19.8079 23.1697 19.8355 23.1613C19.8389 23.1613 19.8424 23.1613 19.8458 23.1596L28.8806 20.0951C29.1928 19.9895 29.4016 19.7047 29.4016 19.383V1.55382C29.4016 1.30919 29.2791 1.07965 29.0738 0.938903ZM2.15422 5.15283L9.63628 2.6161V18.8468L2.15422 21.3836V5.15283ZM11.1889 2.60939L18.8142 5.15451V21.3869L11.1889 18.8418V2.61107V2.60939ZM27.8489 18.8452L20.3668 21.3819V5.15283L27.8489 2.6161V18.8468V18.8452Z" fill="#BA9CC5"/>
            </svg>
          </span>
        </a>
      </nav>

      <!-- <div class="counter" v-if="view == 'list'">
        <h5 v-if="results.range && results.data.length">
          Results
          <span>{{ results.range }}</span>
        </h5>
        <a href class="filter_toggle" @click.prevent="onShowFiltersClick">
          <i class="fa fa-sliders-h"></i> Filter Results
        </a>
      </div> -->
      
      <!-- <div class="counter" v-if="view == 'map'">
        <h5>
          Results <span>{{ results.total }}</span>
        </h5>
        <a href class="filter_toggle" @click.prevent="onShowFiltersClick">
          <i class="fa fa-sliders-h"></i> Filter Results
        </a>
      </div> -->
      <select @change.prevent="onClickSort" class="form__select sort-field__field" id="sort">
        <option 
          v-bind:key="s.value"
          :selected="s.value == Sort"
          v-for="s in sort_options"
          v-bind:value="s.value"
        >{{ s.name }}</option>
      </select>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "Navbar",
  data: () => {
    return {
      sort_options: [
        { name: "Best Match", value: "relevance,networks,availability_soonest,distance" },
        { name: "Next Available Appointment", value: "availability_soonest,networks,distance" },
        { name: "Closest to my Proximity", value: "networks,distance" }
      ]
    };
  },

  computed: {
    ...mapGetters(["view", "filters_hidden", "results", "Sort", "enable_map_features"])
  },
  mounted() {
	  this.setView(this.view);
  },
  methods: {
    ...mapActions(["fetchResults"]),
    setView(view) {
      this.$store.commit("view", view);
      this.fetchResults();
      let collapsibleContent = document.getElementById('collapsible-content');
      if ( collapsibleContent && view == 'list' ) collapsibleContent.style.display='block'
      else if ( collapsibleContent && view == 'map' ) collapsibleContent.style.display='none';
    },
    onShowFiltersClick() {
      this.$store.commit("filters_hidden", false);
    },
    onClickSort(e) {
      const value = e.target.value || null;
      this.selected = value;
      this.$store.commit("Sort", this.selected);
      this.fetchResults();
    },
  }
};
</script>
