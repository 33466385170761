<template>
    <div class="providers-list-result">
        <div v-if="!results || !results.providers || !results.providers.length && !resultsLoading" class="no-results">
            <slot name="noResultContent"></slot>
        </div>
        <div v-for="(result, index) in results.providers">
            <ProviderCardResult :key="result.id" :doctor="result" />
            <MyChristusBar v-if="index >= 0 && index < 1" />
        </div>
        <div v-if="results.page == results.totalPages && showInsurancesBlock" class="insurance-card">
            <div class="insurance-card__wrap">
                <h2>Looking for more doctors?</h2>
                <p class="insurance-card__count">{{ insuranceResults }} more doctors are available</p>
                <p>Insurance information is not available for all providers. There may be more providers available that accept your insurance. If you would like to see more results, remove the insurance filter and call the provider's office to inquire if they accept your insurance.</p>
                <button @click.prevent="onRemoveInsurances" class="btn-primary">Disable Insurance Filter</button>
            </div>
        </div>
    </div>
</template>

<script>
    import ProviderCardResult from './ProviderCardResult.vue';
    import MyChristusBar from './MyChristusBar.vue';
    import { mapActions, mapMutations, mapGetters } from "vuex";

    export default {
        name: "ProvidersListResult",
        props: {
            locationItems: Array
        },
        computed: {
            ...mapGetters(["results", "resultsLoading", "resultsLabel", "ActiveFilters", "HasUrlParams", "showInsurancesBlock"]),
            insuranceResults: function() {
                return this.results.totalCountWithoutInsurance - this.results.totalCount;
            }
        },
        components: {
            ProviderCardResult,
            MyChristusBar
        },
        methods: {
            ...mapActions(["fetchResults", "getParamsFromUrl"]),
            ...mapMutations(['removeFilter']),
            onRemoveInsurances(){
                if (this.ActiveFilters.hasOwnProperty('Insurances')){
                    this.removeFilter('Insurances');
                    this.$store.commit('Insurances', null);
                    this.$store.commit('showInsurancesBlock', false);
                    this.fetchResults();
                }
            }
        },
        mounted() {
        },
    };
</script>  