<template>
    <div class="c-banner">
        <div class="c-banner__content">
            <p class="c-banner__content-highlighted">Looking to schedule an appointment with a provider you're a current patient of?</p>
            <p>See available times and schedule directly in <a href="#" class="arrow"><b>MyCHRISTUS</b><svg width="20" height="12" viewBox="0 0 20 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10.8321 11.7067C10.5433 11.8865 10.1777 11.888 9.88754 11.7105C9.31671 11.3613 9.31343 10.5333 9.88148 10.1797L15.3909 6.75H0.722656V5.25H15.3909L9.88148 1.82027C9.31343 1.46665 9.31671 0.638656 9.88754 0.289539C10.1777 0.112048 10.5433 0.113493 10.8321 0.293275L19.9993 6L10.8321 11.7067Z" fill="white"/></svg></a></p>
        </div>
    </div>
</template>
  
<script>
  export default {
    name: "Banner",
    data: () => {
      return {
      };
    },
    computed: {
    },
    methods: {
    }
  };
</script>  