var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.results.totalCount
      ? _c("div", { staticClass: "paginator" }, [
          _vm.results.totalPages > 1
            ? _c("div", { staticClass: "col" }, [
                _c(
                  "div",
                  { staticClass: "controls" },
                  [
                    _c(
                      "button",
                      {
                        staticClass: "prev",
                        attrs: { disabled: _vm.currentPage <= 1, href: "" },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.onChangePage("prev")
                          }
                        }
                      },
                      [_c("i", { staticClass: "fa fa-chevron-left" })]
                    ),
                    _vm._v(" "),
                    _vm._l(_vm.pager, function(p) {
                      return _c(
                        "button",
                        {
                          key: p,
                          staticClass: "page",
                          class: { active: _vm.currentPage == p },
                          attrs: { disabled: p == "..." },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.onChangePage(p)
                            }
                          }
                        },
                        [_c("span", [_vm._v(_vm._s(p))])]
                      )
                    }),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "next",
                        attrs: {
                          disabled:
                            _vm.currentPage > _vm.results.totalPages - 1,
                          href: ""
                        },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.onChangePage("next")
                          }
                        }
                      },
                      [_c("i", { staticClass: "fa fa-chevron-right" })]
                    )
                  ],
                  2
                )
              ])
            : _vm._e()
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-1f40949e", { render: render, staticRenderFns: staticRenderFns })
  }
}