<template>
    <!-- DAY -->
    <div class="schedule-calendar__day">
        <div class="schedule-calendar__header">
            <span class="schedule-calendar__weekday">{{ weekday }}</span>
            <span class="schedule-calendar__date">{{ month + ' ' + day }}</span>
        </div>
        <div class="schedule-calendar__hours">

            <ScheduleSlot v-for="( item, index ) in this.slotsToRender" 
                :key="scheduleDay.Date + '-' + index" 
                :scheduleSlot="item" 
                :scheduleBaseUrl="scheduleBaseUrl"
                :providerId="providerId"
                :locationId="locationId"
                :isActive=true />
                
            <ScheduleSlot v-for="index in emptySlots" 
                :key="scheduleDay.Date+'-deactive-'+index" 
                :scheduleBaseUrl="scheduleBaseUrl"
                :providerId="providerId"
                :locationId="locationId"
                :isActive=false />
        </div>
    </div>
    <!-- EOD -->
</template>

<script>
import ScheduleSlot from './ScheduleSlot.vue';
export default {
    name: "ScheduleDay",
    data: () => {
        return {
            maxSlotsPerDay: 4,
            emptySlots: null,
            date: null,
            day: null,
            weekday: null,
            month: null,
            dayNumber: null
        };
    },
    props: {
        scheduleBaseUrl: String,
        scheduleDay: Object,
        locationId: String,
        providerId: String
    },
    computed: {
        slotsToRender: function(){
            let slots = [];
            if ( this.scheduleDay.Slots.length <= this.maxSlotsPerDay ) { 
                slots = this.scheduleDay.Slots
             } else {
                slots = this.scheduleDay.Slots.slice(0, this.maxSlotsPerDay - 1);
                slots.push({
                    "Time": `+${this.scheduleDay.Slots.length - this.maxSlotsPerDay + 1} more`
                });
             }
            return slots;
        }
    },
    methods: {
        init() {
            let comp = this;
            if(comp.scheduleDay && comp.scheduleDay.Raw){
                comp.date = new Date(`${comp.scheduleDay.Raw}T00:00:00`);
                comp.weekday = comp.date.toLocaleString('en-us', { weekday:'short' });
                comp.month = comp.date.toLocaleString('default', {month: 'short'});
                comp.day = comp.date.toLocaleString('default', {day: 'numeric'});
            }

            this.emptySlots = ( this.scheduleDay.Slots.length < this.maxSlotsPerDay ) ? this.maxSlotsPerDay - this.scheduleDay.Slots.length : 0;
        }
    },
    mounted() {
        this.init()
    },
    components: {
        ScheduleSlot
    }
};
</script>  