<template>
  <div>
    <div class="paginator" v-if="results.totalCount">

      <div class="col" v-if="results.totalPages > 1">
        <div class="controls">
          <button
            :disabled="currentPage <= 1"
            href
            class="prev"
            @click.prevent="onChangePage('prev')"
          >
            <i class="fa fa-chevron-left"></i>
          </button>
          <button
            :disabled="p == '...'"
            :class="{active: currentPage == p}"
            :key="p"
            class="page"
            v-for="p in pager"
            @click.prevent="onChangePage(p)"
          ><span>{{ p }}</span></button>
          <button
            :disabled="currentPage > results.totalPages - 1"
            href
            class="next"
            @click.prevent="onChangePage('next')"
          >
            <i class="fa fa-chevron-right"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "Paginator",
  data() {
    return {
      currentPage: 1,
      total: 0,
      pager: []
    };
  },
  computed: {
    ...mapGetters(["results", "Page", "theme"])
  },
  created() {
    this.$store.subscribe((mutation) => {
      if (mutation.type === "results") {
        this.currentPage = this.Page;
        this.pager = this.pagination(
          this.currentPage,
          mutation.payload.totalPages
        );
      }
    });

    setTimeout(() => {
      if(this.results){
        this.pager = this.pagination(
          this.currentPage,
          this.results.totalPages
        );
      }
    }, 500);

  },
  methods: {
    ...mapActions(["fetchResults"]),
    pagination(currentPage, pageCount) {
      let delta = 1,
        left = currentPage - delta,
        right = currentPage + delta + 1,
        result = [];

      result = Array.from({ length: pageCount }, (v, k) => k + 1).filter(
        i => i && i >= left && i < right
      );

      if (result.length > 1) {
        // Add first page and dots
        if (result[0] > 1) {
          if (result[0] > 2) {
            result.unshift("...");
          }
          result.unshift(1);
        }

        // Add dots and last page
        if (result[result.length - 1] < pageCount) {
          if (result[result.length - 1] !== pageCount - 1) {
            result.push("...");
          }
          result.push(pageCount);
        }
      }
      return result;
    },
    scrollToTop() {
        window.scrollTo(0,0);
        let locList = document.querySelector(".location-list");
        if(locList){
          locList.scrollTo(0,0);
        }
    },
    onChangePage(direction) {
      if (direction == "next") {
        if (this.currentPage < this.results.totalPages) {
          this.currentPage++;
        }
      } else if (direction == "prev") {
        if (this.currentPage > 0) {
          this.currentPage--;
        }
      } else {
        this.currentPage = direction;
      }
      this.$store.commit("Page", this.currentPage);
      this.fetchResults({ pageOnly: true});
      this.scrollToTop();
    }
  }
};
</script>