var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "c-category-list" }, [
    _c("p", { staticClass: "c-category-list__results-label" }, [
      _vm._v(
        _vm._s(
          _vm.results && _vm.results.totalCount
            ? _vm.results.totalCount.toLocaleString()
            : 0
        ) +
          " " +
          _vm._s(_vm.resultLabel) +
          " "
      ),
      !_vm.isEmpty(_vm.ActiveFilters) ? _c("span", [_vm._v("for:")]) : _vm._e()
    ]),
    _vm._v(" "),
    !_vm.isEmpty(_vm.ActiveFilters)
      ? _c(
          "div",
          { staticClass: "c-category-list__wrapper" },
          [
            _vm._l(_vm.ActiveFilters, function(value, key) {
              return _c(
                "div",
                { key: key, staticClass: "category-cta" },
                [
                  Array.isArray(value)
                    ? _vm._l(value, function(item, index) {
                        return _c(
                          "div",
                          { key: index, staticClass: "cta__item-wrap" },
                          [
                            _c(
                              "button",
                              {
                                staticClass: "cta__link",
                                on: {
                                  click: function($event) {
                                    $event.preventDefault()
                                    return _vm.toggleFilterOff(key)
                                  }
                                }
                              },
                              [
                                _c("span", { staticClass: "cta__link-text" }, [
                                  _vm._v(_vm._s(item))
                                ]),
                                _vm._v(" "),
                                _c(
                                  "svg",
                                  {
                                    attrs: {
                                      width: "10",
                                      height: "10",
                                      viewBox: "0 0 10 10",
                                      fill: "none",
                                      xmlns: "http://www.w3.org/2000/svg"
                                    }
                                  },
                                  [
                                    _c("path", {
                                      attrs: {
                                        d:
                                          "M5.44739 4.55087C5.69533 4.7988 5.69533 5.20145 5.44739 5.44938L1.63915 9.25763C1.39121 9.50556 0.98857 9.50556 0.740637 9.25763C0.492704 9.00969 0.492704 8.60705 0.740637 8.35912L4.10062 4.99913L0.742621 1.63915C0.494688 1.39121 0.494688 0.98857 0.742621 0.740637C0.990553 0.492704 1.3932 0.492704 1.64113 0.740637L5.44938 4.54889L5.44739 4.55087Z",
                                        fill: "#0072CE"
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c("path", {
                                      attrs: {
                                        d:
                                          "M4.62953 5.4474C4.3816 5.19946 4.3816 4.79682 4.62953 4.54889L8.43778 0.740637C8.68571 0.492705 9.08835 0.492705 9.33629 0.740638C9.58422 0.98857 9.58422 1.39121 9.33629 1.63915L5.9763 4.99913L9.3343 8.35912C9.58224 8.60705 9.58224 9.00969 9.3343 9.25763C9.08637 9.50556 8.68373 9.50556 8.43579 9.25763L4.62755 5.44938L4.62953 5.4474Z",
                                        fill: "#0072CE"
                                      }
                                    })
                                  ]
                                )
                              ]
                            )
                          ]
                        )
                      })
                    : [
                        _c("div", { staticClass: "cta__item-wrap" }, [
                          _c(
                            "button",
                            {
                              staticClass: "cta__link",
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  return _vm.toggleFilterOff(key)
                                }
                              }
                            },
                            [
                              _c("span", { staticClass: "cta__link-text" }, [
                                _vm._v(_vm._s(_vm.getFilterValue(key)))
                              ]),
                              _vm._v(" "),
                              _c(
                                "svg",
                                {
                                  attrs: {
                                    width: "10",
                                    height: "10",
                                    viewBox: "0 0 10 10",
                                    fill: "none",
                                    xmlns: "http://www.w3.org/2000/svg"
                                  }
                                },
                                [
                                  _c("path", {
                                    attrs: {
                                      d:
                                        "M5.44739 4.55087C5.69533 4.7988 5.69533 5.20145 5.44739 5.44938L1.63915 9.25763C1.39121 9.50556 0.98857 9.50556 0.740637 9.25763C0.492704 9.00969 0.492704 8.60705 0.740637 8.35912L4.10062 4.99913L0.742621 1.63915C0.494688 1.39121 0.494688 0.98857 0.742621 0.740637C0.990553 0.492704 1.3932 0.492704 1.64113 0.740637L5.44938 4.54889L5.44739 4.55087Z",
                                      fill: "#0072CE"
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("path", {
                                    attrs: {
                                      d:
                                        "M4.62953 5.4474C4.3816 5.19946 4.3816 4.79682 4.62953 4.54889L8.43778 0.740637C8.68571 0.492705 9.08835 0.492705 9.33629 0.740638C9.58422 0.98857 9.58422 1.39121 9.33629 1.63915L5.9763 4.99913L9.3343 8.35912C9.58224 8.60705 9.58224 9.00969 9.3343 9.25763C9.08637 9.50556 8.68373 9.50556 8.43579 9.25763L4.62755 5.44938L4.62953 5.4474Z",
                                      fill: "#0072CE"
                                    }
                                  })
                                ]
                              )
                            ]
                          )
                        ])
                      ]
                ],
                2
              )
            }),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass: "cta",
                attrs: { href: "#" },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.onClearAll.apply(null, arguments)
                  }
                }
              },
              [_vm._v("Clear All")]
            )
          ],
          2
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-6cc65acc", { render: render, staticRenderFns: staticRenderFns })
  }
}