<template>
    <div :class="rootClass">
      
      <div id="filter-sidebar" :class="{'active': mobileFilterActive === true, 'collapsible-section': true}" class="collapsible-section">

          <!-- Collapsible Content -->
          <div id="collapsible-content" class="collapsible-section--content">
            <div class="collapsible-section--content-wrapper">

              <!-- BEGINS SIDEBAR CONTENT -->
              <DoctorSearchFilters />
              <!-- ENDS SIDEBAR CONTENT -->
              
            </div>
          </div>

          <!-- Tab buttons -->
          <ul class="collapsible-section--tab-buttons">
            <li>
              <button aria-expanded="false" aria-controls="collapsible-content" class="tab-button">
                <i class="fas fa-chevron-up"></i>
                <span class="tab-button--shrinked">Filter Results</span>
                <span class="tab-button--expanded">Collapse</span>
              </button>
            </li>
          </ul>

      </div>

    </div>
</template>

<script>
import SearchFilter from "./SearchFilter.vue";
import DistanceFilter from "./DistanceFilter.vue";
import CategoryFilter from "./CategoryFilter.vue";
import { mapActions, mapGetters } from "vuex";
import $ from 'jquery';
import DoctorSearchFilters from "./DoctorSearchFilters.vue";

export default {
  name: "Sidebar",
  computed: {
    ...mapGetters(["filters_hidden", "theme", "mobileFilterActive"]),
    rootClass: function(){
      let cssClass = 'sidebar';
      switch(this.theme){
        case 'minimal':
          cssClass = 'location__filter';
        break;
      }

      return cssClass;
    }
  },
  components: {
    SearchFilter,
    DistanceFilter,
    CategoryFilter,
    DoctorSearchFilters
},
  methods: {
    ...mapActions(["fetchResults"]),
    onHideFiltersClick() {
      this.$store.commit("filters_hidden", true);
    },
    onResetFilter() {
      this.filters.forEach(filter => {
        const type = filter.type.charAt(0).toUpperCase() + filter.type.slice(1);
        filter.data.forEach(item => {
          if (item.checked) {
            item.checked = false;
          }
        });
        this.$store.commit(type, null);
        this.fetchResults();
      });
    },
    bool (v) {
      return v=='true' ? true : false;
    }
  },
  created() {
    this.$store.subscribe(mutation => {
    if (mutation.type === "filters") {
        const filters = mutation.payload;
        this.filters = filters;
      }
    });
  },
  mounted() {
    window.usingFadSearch = true;

    $(document).ready(function () {
      $('#filter-sidebar .tab-button').click(function () {
        
        $('.collapsible-section--content').animate({
            width: 'toggle'
        }, 1000);
        const ariaExpanded = this.ariaExpanded == 'false' ? 'true' : 'false';
        this.setAttribute('aria-expanded', ariaExpanded )
      });
    });
  }
};
</script>
