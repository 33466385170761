<template>
    <!-- SLOT -->
    <div 
        class="schedule-calendar__time"
        v-if="scheduleSlot"
        :class="{ deactive: !isActive, online: scheduleSlot.Virtual }"
    >
        <a v-if="isActive" :href="slotUrl">{{ slotLabel }}</a>
    </div>
    <!-- EOD -->


    <!-- ===================== -->
    <!-- DEACTIVE DAYS EXAMPLE -->
    <!-- ===================== -->
    <div v-else class="schedule-calendar__time deactive">---</div>
   

</template>

<script>
    export default {
        name: "ScheduleDay",
        data: () => {
            return {
                isMoreThanMaxSlots: false
            };
        },
        props: {
            scheduleBaseUrl: String,
            scheduleSlot: Object,
            isActive: Boolean,
            locationId: String,
            providerId: String
        },
        computed: {
            slotLabel : function(){
                return this.scheduleSlot.TimeFormatted ?? this.scheduleSlot.Time;
            },
            slotUrl: function(){
                let url = '';
                if(this.scheduleSlot.Url){
                    url = this.scheduleSlot.Url.indexOf('location') === -1 && this.locationId ? `${this.scheduleSlot.Url}&locationID=${encodeURIComponent(this.locationId)}` : this.scheduleSlot.Url;
                    url = url.indexOf('provider') === -1 && this.providerId ? `${url}&providerID=${encodeURIComponent(this.providerId)}` : url;
                }
                else{
                    url = this.scheduleBaseUrl;
                }
                return url;
            }
        },
        methods: {
            init(){
                this.isActive = ( this.scheduleSlot && this.scheduleSlot.Time ) ? true : false;
            }
        },
        mounted() {
            this.init();
        }
    };
</script>  