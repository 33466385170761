import Vue from 'vue'
import Vuex from 'vuex'
// modules
import filters from './modules/filters.js'
import results from './modules/results.js'
import maps from './modules/maps.js'
import typeahead from './modules/typeahead.js'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        api_url: '',
        api_key: '',
        app_view_mode: '',
        banner_only: false,
        banner_homepage: false,
        banner_button_title: '',
        current_location_icon_url: '',
        default_image_url: '',
        enable_map_features: false,
        theme: 'default',
        view: "list",
        filters_hidden: true,
        filters_loaded: false,
        default_page_size: 10,
        specialties_filter: [],
        specialties_key: 0,
        provider_name_filter: '',
        location_filter: '',
        distance_filter: '',
        insurance_filter: '',
        next_availability_filter: '',
        preferred_times_filter: [],
        genders_filter: [],
        languages_filter: []
    },
    mutations: {
        api_url: (state, api_url) => { state.api_url = api_url },
        api_key: (state, api_key) => { state.api_key = api_key },
        app_view_mode: (state, app_view_mode) => { state.app_view_mode = app_view_mode },
        banner_only: (state, banner_only) => { state.banner_only = banner_only },
        banner_homepage: (state, banner_homepage) => { state.banner_homepage = banner_homepage },
        banner_button_title: (state, banner_button_title) => { state.banner_button_title = banner_button_title },
        current_location_icon_url: (state, current_location_icon_url) => { state.current_location_icon_url = current_location_icon_url },
        default_image_url: (state, default_image_url) => { state.default_image_url = default_image_url },
        enable_map_features: (state, enable_map_features) => { state.enable_map_features = enable_map_features },
        theme: (state, theme) => { state.theme = theme },
        view: (state, view) => { state.view = view },
        filters_hidden: (state, filters_hidden) => { state.filters_hidden = filters_hidden },
        filters_loaded: (state, filters_loaded) => { state.filters_loaded = filters_loaded },
        default_page_size: (state, default_page_size) => { state.default_page_size = default_page_size },
        specialties_filter: (state, specialties_filter) => { state.specialties_filter.push(specialties_filter) },
        specialties_key: (state, specialties_key) => { state.specialties_key = specialties_key },
        provider_name_filter: (state, provider_name_filter) => { state.provider_name_filter = provider_name_filter },
        location_filter: (state, location_filter) => { state.location_filter = location_filter },
        distance_filter: (state, distance_filter) => { state.distance_filter = distance_filter },
        insurance_filter: (state, insurance_filter) => { state.insurance_filter = insurance_filter },
        next_availability_filter: (state, next_availability_filter) => { state.next_availability_filter = next_availability_filter },
        preferred_times_filter: (state, preferred_times_filter) => { state.preferred_times_filter.push(preferred_times_filter)},
        genders_filter: (state, genders_filter) => { state.genders_filter.push(genders_filter)},
        languages_filter: (state, languages_filter) => { state.languages_filter.push(languages_filter) },
        search_url: (state, search_url) => { state.search_url = search_url },
    },
    getters: {
        api_url: (state) => state.api_url,
        api_key: (state) => state.api_key,
        app_view_mode: (state) => state.app_view_mode,
        banner_only: (state) => state.banner_only,
        banner_homepage: (state) => state.banner_homepage,
        banner_button_title: (state) => state.banner_button_title,
        enable_map_features: (state) => state.enable_map_features,
        current_location_icon_url: (state) => state.current_location_icon_url,
        default_image_url: (state) => state.default_image_url,
        theme: (state) => state.theme,
        view: (state) => state.view,
        filters_hidden: (state) => state.filters_hidden,
        filters_loaded: (state) => state.filters_loaded,
        default_page_size: (state) => state.default_page_size,
        specialties_filter: (state) => state.specialties_filter,
        specialties_key: (state) => state.specialties_key,
        provider_name_filter: (state) => state.provider_name_filter,
        location_filter: (state) => state.location_filter,
        distance_filter: (state) => state.distance_filter,
        insurance_filter: (state) => state.insurance_filter,
        next_availability_filter: (state) => state.next_availability_filter,
        preferred_times_filter: (state) => state.preferred_times_filter,
        genders_filter: (state) => state.genders_filter,
        languages_filter: (state) => state.languages_filter,
        search_url: (state) => state.search_url,
    },
    actions: {
    },
    modules: {
        filters,
        results,
        maps,
        typeahead
    }
})
