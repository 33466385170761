<template>
    <div class="provider-list-callout">
        <span>Looking to schedule an appointment with your current provider?</span>
        <span>
          See available times and schedule directly in 
          <a href="https://www.christushealth.org/plan-care/mychristus">
            MyCHRISTUS 
            <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M9.24954 16.9088C8.83781 17.3608 8.12753 17.3639 7.71183 16.9156C7.34292 16.5177 7.34021 15.9035 7.7056 15.5024L12.6594 10.064H0.484375V7.86838H12.6594L7.7056 2.42999C7.34021 2.02886 7.34292 1.41473 7.71183 1.01684C8.12753 0.56847 8.83781 0.571602 9.24955 1.02362L16.4844 8.9662L9.24954 16.9088Z" fill="white"/>
            </svg>
          </a>
        </span>
    </div>
</template>
  
<script>
  export default {
    name: "MyChristusBar",
    data: () => {
      return {
      };
    },
    computed: {
    },
    methods: {
    }
  };
</script>  