var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "providers-list-result" },
    [
      !_vm.results ||
      !_vm.results.providers ||
      (!_vm.results.providers.length && !_vm.resultsLoading)
        ? _c(
            "div",
            { staticClass: "no-results" },
            [_vm._t("noResultContent")],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _vm._l(_vm.results.providers, function(result, index) {
        return _c(
          "div",
          [
            _c("ProviderCardResult", {
              key: result.id,
              attrs: { doctor: result }
            }),
            _vm._v(" "),
            index >= 0 && index < 1 ? _c("MyChristusBar") : _vm._e()
          ],
          1
        )
      }),
      _vm._v(" "),
      _vm.results.page == _vm.results.totalPages && _vm.showInsurancesBlock
        ? _c("div", { staticClass: "insurance-card" }, [
            _c("div", { staticClass: "insurance-card__wrap" }, [
              _c("h2", [_vm._v("Looking for more doctors?")]),
              _vm._v(" "),
              _c("p", { staticClass: "insurance-card__count" }, [
                _vm._v(
                  _vm._s(_vm.insuranceResults) + " more doctors are available"
                )
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "Insurance information is not available for all providers. There may be more providers available that accept your insurance. If you would like to see more results, remove the insurance filter and call the provider's office to inquire if they accept your insurance."
                )
              ]),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn-primary",
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.onRemoveInsurances.apply(null, arguments)
                    }
                  }
                },
                [_vm._v("Disable Insurance Filter")]
              )
            ])
          ])
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-2df58b86", { render: render, staticRenderFns: staticRenderFns })
  }
}