<template>
    <div class="c-category-list">
        <p class="c-category-list__results-label">{{ (results && results.totalCount) ? results.totalCount.toLocaleString() : 0 }} {{ resultLabel }} <span v-if="!isEmpty(ActiveFilters)">for:</span></p>
        <div v-if="!isEmpty(ActiveFilters)" class="c-category-list__wrapper">
            <div v-for="(value, key) in ActiveFilters" :key="key" class="category-cta">
                <template v-if="Array.isArray(value)">
                    <div v-for="(item, index) in value" :key="index" class="cta__item-wrap">
                        <button @click.prevent="toggleFilterOff(key)" class="cta__link ">
                            <span class="cta__link-text">{{ item }}</span>
                            <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M5.44739 4.55087C5.69533 4.7988 5.69533 5.20145 5.44739 5.44938L1.63915 9.25763C1.39121 9.50556 0.98857 9.50556 0.740637 9.25763C0.492704 9.00969 0.492704 8.60705 0.740637 8.35912L4.10062 4.99913L0.742621 1.63915C0.494688 1.39121 0.494688 0.98857 0.742621 0.740637C0.990553 0.492704 1.3932 0.492704 1.64113 0.740637L5.44938 4.54889L5.44739 4.55087Z" fill="#0072CE"/>
                            <path d="M4.62953 5.4474C4.3816 5.19946 4.3816 4.79682 4.62953 4.54889L8.43778 0.740637C8.68571 0.492705 9.08835 0.492705 9.33629 0.740638C9.58422 0.98857 9.58422 1.39121 9.33629 1.63915L5.9763 4.99913L9.3343 8.35912C9.58224 8.60705 9.58224 9.00969 9.3343 9.25763C9.08637 9.50556 8.68373 9.50556 8.43579 9.25763L4.62755 5.44938L4.62953 5.4474Z" fill="#0072CE"/>
                            </svg>
                        </button>
                    </div>
                </template>
                <template v-else>
                    <div class="cta__item-wrap">
                        <button @click.prevent="toggleFilterOff(key)" class="cta__link ">
                            <span class="cta__link-text">{{ getFilterValue(key) }}</span>
                            <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M5.44739 4.55087C5.69533 4.7988 5.69533 5.20145 5.44739 5.44938L1.63915 9.25763C1.39121 9.50556 0.98857 9.50556 0.740637 9.25763C0.492704 9.00969 0.492704 8.60705 0.740637 8.35912L4.10062 4.99913L0.742621 1.63915C0.494688 1.39121 0.494688 0.98857 0.742621 0.740637C0.990553 0.492704 1.3932 0.492704 1.64113 0.740637L5.44938 4.54889L5.44739 4.55087Z" fill="#0072CE"/>
                            <path d="M4.62953 5.4474C4.3816 5.19946 4.3816 4.79682 4.62953 4.54889L8.43778 0.740637C8.68571 0.492705 9.08835 0.492705 9.33629 0.740638C9.58422 0.98857 9.58422 1.39121 9.33629 1.63915L5.9763 4.99913L9.3343 8.35912C9.58224 8.60705 9.58224 9.00969 9.3343 9.25763C9.08637 9.50556 8.68373 9.50556 8.43579 9.25763L4.62755 5.44938L4.62953 5.4474Z" fill="#0072CE"/>
                            </svg>
                        </button>
                    </div>
                </template>
            </div>
            <a href="#" @click.prevent="onClearAll" class="cta">Clear All</a>
        </div>
    </div>
</template>

<script>
    import CategoryCta from './CategoryCta.vue';
    import { mapGetters, mapActions, mapMutations } from "vuex";

    export default {
        name: "CategoryList",
        computed: {
            ...mapGetters(["results", "ActiveFilters", "filterRemoved", "getFilterValue"]),
            resultLabel: function(){
                return this.results && this.results.totalCount === 1 ? "result" : "results";
            }
        },
        data: () => {
            return {}
        },
        methods: {
            ...mapActions(["fetchResults", "removeArrayElement"]),
            ...mapMutations(['removeFilter']),
            toggleFilterOff(key){
                if (this.ActiveFilters.hasOwnProperty(key)){
                    const urlParams = new URLSearchParams(window.location.search);
                    this.removeFilter(key);
                    switch (key) {
                        case 'Keyword':
                            this.$store.commit('KeywordCategory', null);
                            this.$store.commit("Keyword", null);
                            if(urlParams.get('Keyword')){
                                urlParams.delete('Keyword');
                                if(urlParams.get('conditions_symptoms')){ urlParams.delete('conditions_symptoms'); }
                                if(urlParams.get('location_name')){ urlParams.delete('location_name'); }
                                if(urlParams.get('provider_name')){ urlParams.delete('provider_name'); }
                                if(urlParams.get('specialties')){ urlParams.delete('specialties'); }
                                window.history.replaceState({}, '', `${location.pathname}?${urlParams}`);
                            }
                            this.$store.commit("keywordRemoved", true);
                            break;
                        case 'Specialties':
                            this.$store.commit('Specialties', '');
                            if(urlParams.get('SpecialtiesFilter')){
                                urlParams.delete('SpecialtiesFilter');
                                setTimeout(() => {
                                    window.history.replaceState({}, '', `${location.pathname}?${urlParams}`);
                                }, 500);
                            }
                            break;
                        case 'ProviderName':
                            this.$store.commit('ProviderName', '');
                            if(urlParams.get('ProviderName')){
                                urlParams.delete('ProviderName');
                                window.history.replaceState({}, '', `${location.pathname}?${urlParams}`);
                            }
                            break;
                        case 'PreferredTimes':
                        case 'Genders':
                        case 'Languages':
                            this.$store.commit(key, []);
                            break;
                        case 'LocationName':
                            this.$store.commit('Longitude', null);
                            this.$store.commit('Latitude', null);
                            this.$store.commit('Location', []);
                            this.$store.dispatch('removeLocationCookie');
                            this.$store.commit("distanceRemoved", true);
                            break;
                        case 'Insurances':
                            this.$store.commit('showInsurancesBlock', false);
                        default:
                            this.$store.commit(key, null);
                    }
                    this.fetchResults();
                }
                this.$store.commit("filterRemoved", true);
            },
            isEmpty(obj){
                if(Object.keys(obj).length === 0){
                    return true;
                }
            },
            onClearAll(){
                this.$store.commit("clearAll", true);
                this.$store.commit("distanceRemoved", true);
            }
        },
        mounted() {},
        components: {
            CategoryCta
        }
  };
</script>  