<template>
    <div>
        <div class="locations-tabs__wrapper" v-if="!banner_homepage">
            <nav class="locations-tabs">
                <div class="locations-tabs__inner">
                    <ul class="tabs" id="" data-deep-link="true" data-update-history="true" data-tabs="ow5629-tabs" role="tablist">

                        <li class="tabs-title is-active" role="presentation">
                            <a href="/find-care" role="tab" aria-controls="providers-results" aria-selected="true" id="providers-results-label" tabindex="0">

                                <span class="tabs-icon" aria-hidden="true" role="presentation">
                                    <svg width="31" height="27" aria-hidden="true">
                                        <use xlink:href="/assets/images/static/sprite.svg#find-a-doc"></use>
                                    </svg>
                                </span>

                                Find a Doctor
                            </a>
                        </li>
                        <li class="tabs-title " role="presentation">
                            <a href="/locations" role="tab" aria-controls="locations-results" aria-selected="false" id="locations-results-label" tabindex="-1">

                                <span class="tabs-icon" aria-hidden="true" role="presentation">
                                    <svg width="31" height="27" aria-hidden="true">
                                        <use xlink:href="/assets/images/static/sprite.svg#hospital"></use>
                                    </svg>
                                </span>

                                Find a Location
                            </a>
                        </li>
                    </ul>
                </div>
            </nav>
        </div>
        <div class="c-keyboard-search-hero" v-bind:class="{ 'search-hero-homepage' : banner_homepage }">          
            <div class="c-keyboard-search-hero--content">
                <div class="c-keyboard-search-hero--content-wrapper typeahead-container">
                    <h1 v-if="!banner_homepage">Find a Doctor</h1>
                    <label>
                        <span><b>Enter a Specialty, Condition, or Doctor Name</b></span>
                        <div class="input-set">
                            <input
                                type="search"
                                name="keyboard-search-hero" 
                                id="keyboard-search-hero" 
                                class="form__input"
                                data-typeahead-type="keyboard-search-hero"
                                data-typeahead-min-length="1"
                                aria-label="Enter a Specialty, Condition, or Doctor Name"
                                placeholder=""
                                ref="keywordField"
                                v-model="search_text"
                                @keyup="onSearchChange"
                                @keydown="handleKeydown"
                                @blur="onFieldLostFocus"
                                autocomplete="off">

                            <button @click="onSearchClick" class="keyboard-search-hero--submit">{{ bannerButtonTitle }}</button>
                            <button @click.prevent="openFilters" class="keyboard-search-hero--filter">Sort and Filter <span v-if="!isEmpty(ActiveFilters) && filtersCount(ActiveFilters) != 0">({{ filtersCount(ActiveFilters) }})</span></button>

                            <svg class="icn-search" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7.24857 0.800781H7.50057C9.43557 0.917781 11.2806 1.91678 12.4146 3.61778C13.9716 5.95778 13.8006 9.00878 12.0456 11.1418L15.2046 14.3008L14.3046 15.2008L11.1456 12.0418C10.0926 12.8968 8.78757 13.4008 7.42857 13.4638H7.13157C4.10757 13.4638 1.50657 11.3308 0.921567 8.36978C0.336567 5.40878 1.92057 2.43878 4.71957 1.28678C5.53857 0.944781 6.40257 0.791781 7.24857 0.809781V0.800781ZM7.14057 2.06978C4.71957 2.06978 2.64057 3.77978 2.17257 6.14678C1.70457 8.51378 2.97356 10.8898 5.20556 11.8168C5.79956 12.0598 6.42057 12.1858 7.03257 12.2038H7.26657C8.87757 12.1678 10.4166 11.3578 11.3616 9.95378C12.7026 7.94678 12.4416 5.26478 10.7316 3.55478C9.77757 2.60078 8.49056 2.06978 7.14956 2.06978H7.14057Z" fill="#4D4D4D"/>
                            </svg>

                        </div>
                    </label>
                    <div class="typeahead" ref="typeaheadContainer" v-if="searchResults && this.search_text != ''">
                        <div v-for="(array, key, index) in searchResults">
                            <h3 :class="{'typeahead__title--first': index === 0, 'typeahead__title': true}">{{ getCategoryFriendly(key) }}</h3>
                            <ul>
                                <li v-for="(item, index) in array" :class="{ 'highlighted': search_text === item.name }" :key="index">
                                    <button @mousedown.prevent="onUpdateField(key, item.name)" :value="item.name" v-html="item.highlight"></button>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
  import { mapGetters, mapActions, mapMutations } from "vuex";

  export default {
    name: "KeyboardSearchHero",
    data: () => {
      return {
        search_text: "",
        originalSearch_text: '',
        selectedItemIndex: -1,
      };
    },
    props: {
    },
    computed: {
        ...mapGetters(["ActiveFilters", "Keyword", "KeywordCategory", "banner_only", "banner_homepage", "banner_button_title", "search_url", "HasUrlParams", "clearAll"]),
        searchResults() {
            if(this.$store.state.typeahead.SearchResults){
                const entries = Object.entries(this.$store.state.typeahead.SearchResults);
                const filteredEntries = entries.filter(([key, value]) => key !== 'category_names' && value);
                const filteredObject = Object.fromEntries(filteredEntries);
                if((Object.keys(filteredObject).length)){
                    return filteredObject;
                }
            }
            return null;
        },
        categoryNames() {
            if(this.$store.state.typeahead.SearchResults){
                const entries = Object.entries(this.$store.state.typeahead.SearchResults);
                const filteredEntries = entries.filter(([key, value]) => key === 'category_names');
                const filteredObject = Object.fromEntries(filteredEntries);
                return filteredObject;
            }
            return {};
        },
        bannerButtonTitle(){
            return this.banner_button_title || "Search";
        }
    },
    methods: {
        ...mapActions(["fetchResults", "updateActiveFilters"]),
        ...mapMutations(["addOrUpdateFilter", "removeFilter"]),
        getKeywordFromUrl() {
            setTimeout(() => {
                const comp = this;
                const urlParams = new URLSearchParams(window.location.search);
                const keyword = urlParams.get('Keyword');
                if(keyword){
                    comp.search_text = keyword;
                    this.toggleFilter("Keyword", this.search_text);
                }
            }, 1025);
        },
        getCategoryFriendly(key) {
            const comp = this;
            let friendly = key;

            if(comp.categoryNames && comp.categoryNames.category_names){
                friendly = comp.categoryNames.category_names[key] || friendly;
            }

            return friendly;
        },
        onSearchChange: _.debounce(function(e) {
            if(e.keyCode !== 38 && e.keyCode !== 40 && e.key !== 'Enter'){
                if (this.search_text.length < 3) return;
                this.originalSearch_text = this.search_text;
                this.$store.dispatch('fetchSearchResults', this.search_text);
            }
        }, 500),
        onUpdateField(key, value){
            if(this.banner_only && value && key){
                let encodedVal = encodeURI(value);
                let url = `${this.search_url}?${key}=${encodedVal}`;
                window.location = url;
            }
            else{
                this.selected = value;
                this.search_text = this.selected;
                this.$store.commit("Keyword", this.search_text);
                this.$store.commit("KeywordCategory", key);
                this.fetchResults();
                this.$store.commit("SearchResults", null);
                this.toggleFilter("Keyword", this.search_text);
            }
        },
        onFieldLostFocus(){
            this.selectedItemIndex = -1;
            this.$store.commit("SearchResults", null);
        },
        onSearchClick: function() {
            if(this.banner_only && this.search_url){
                if(this.search_text){
                    let url = `${this.search_url}?keyword=${this.search_text}`;
                    window.location = url;
                }
            }
            else{
                this.$store.commit("KeywordCategory", null);
                if (this.search_text == "") {
                    this.$store.commit("Keyword", null);
                    this.fetchResults();
                } else {
                    this.$store.commit("Keyword", this.search_text);
                    this.fetchResults();
                    this.toggleFilter("Keyword", this.search_text);
                }
            }
        },
        handleKeydown(e){
            const comp = this;
            if (this.searchResults && this.search_text != '') {
                if (e.key === 'ArrowDown') {
                    e.preventDefault();
                    this.selectedItemIndex = Math.min(this.selectedItemIndex + 1, this.getTotalItems() - 1);
                    this.selectItem(this.selectedItemIndex);
                } else if (e.key === 'ArrowUp') {
                    e.preventDefault();
                    this.selectedItemIndex = Math.max(this.selectedItemIndex - 1, -1);
                    this.selectItem(this.selectedItemIndex);
                } else if (e.key === 'Enter') {
                    e.preventDefault();
                    comp.onSearchClick();
                }
            }
        },
        getTotalItems() {
            let totalItems = 0;
            for (const key in this.searchResults) {
                totalItems += this.searchResults[key].length;
            }
            return totalItems;
        },
        selectItem(index){
            if (index === -1) {
                this.search_text = this.originalSearch_text;
            } else {
                let currentIndex = 0;
                for (const key in this.searchResults) {
                    const categoryItems = this.searchResults[key];
                    if (index >= currentIndex && index < currentIndex + categoryItems.length) {
                        const itemIndex = index - currentIndex;
                        this.search_text = categoryItems[itemIndex].name;
                        break;
                    }
                    currentIndex += categoryItems.length;
                }
                this.scrollIntoView();
            }
        },
        scrollIntoView() {
            this.$nextTick(() => {
                const container = this.$refs.typeaheadContainer;
                const highlightedItem = container.querySelector('.highlighted');
                if (highlightedItem) {
                highlightedItem.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
                }
            });
        },
        isEmpty(obj){
            if(Object.keys(obj).length === 0){
                return true;
            }
        },
        filtersCount(obj){
            if(obj.hasOwnProperty('Keyword')){
                return Object.keys(obj).length - 1;
            } else {
                return Object.keys(obj).length;
            }
        },
        openFilters(){
            this.$store.commit("mobileFilterActive", true);
        },
        toggleFilter(key, value) {
            if(value){
                this.addOrUpdateFilter({ key, value });
            }
            else{
                this.removeFilter(key);
            }
        }
    },
    mounted(){
        this.getKeywordFromUrl();
    },
    watch: {
        search_text: function(){
            //this.toggleFilter("Keyword", this.search_text);
        },
        'Keyword': {
            immediate: true,
            handler(keyword) {
                if(!keyword){
                    this.search_text = null;
                }
            },
        },
        'HasUrlParams': function(){
            let comp = this;
            if(comp.HasUrlParams){
                comp.search_text = this.Keyword;
                comp.toggleFilter("Keyword", this.Keyword);
                comp.$store.commit("HasUrlParams", false);
            }
        },
        'kaywordRemoved': {
            immediate: true,
            handler(keywordRemoved) {
                if (keywordRemoved === true) {
                //Set static values = to the values in the Store
                this.search_text = null;
                console.log(this.search_text);
                this.$store.commit("keywordRemoved", false);
                }
            },
        },
        'clearAll': function(){
            if (this.clearAll === true) {
                this.search_text = '';
            }
        },
    }
  };
</script>  