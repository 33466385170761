var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "fad-filters js-fad-filter" }, [
      _c(
        "button",
        {
          staticClass: "fad-filters__close",
          on: {
            click: function($event) {
              $event.preventDefault()
              return _vm.closeMobileFilters.apply(null, arguments)
            }
          }
        },
        [
          _vm._v("\n        Cancel\n        "),
          _c(
            "svg",
            {
              attrs: {
                width: "17",
                height: "17",
                viewBox: "0 0 17 17",
                fill: "none",
                xmlns: "http://www.w3.org/2000/svg"
              }
            },
            [
              _c("path", {
                attrs: {
                  d:
                    "M8.80687 7.68102C9.25315 8.13406 9.25315 8.8698 8.80687 9.32284L1.95202 16.2815C1.50575 16.7346 0.780988 16.7346 0.334709 16.2815C-0.11157 15.8285 -0.11157 15.0928 0.334709 14.6397L6.38268 8.50012L0.33828 2.36051C-0.108 1.90747 -0.108 1.17173 0.33828 0.718687C0.784559 0.265646 1.50932 0.265646 1.95559 0.718687L8.81044 7.6774L8.80687 7.68102Z",
                  fill: "white"
                }
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d:
                    "M7.33471 9.31922C6.88844 8.86618 6.88844 8.13044 7.33471 7.67739L14.1896 0.718688C14.6358 0.265647 15.3606 0.265647 15.8069 0.718688C16.2532 1.17173 16.2532 1.90747 15.8069 2.36051L9.7589 8.50012L15.8033 14.6397C16.2496 15.0928 16.2496 15.8285 15.8033 16.2815C15.357 16.7346 14.6323 16.7346 14.186 16.2815L7.33114 9.32284L7.33471 9.31922Z",
                  fill: "white"
                }
              })
            ]
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "form",
        {
          staticClass: "fad-filters__form js-fad-filters-form",
          attrs: { autocomplete: "off" }
        },
        [
          _c("div", { staticClass: "fad-filters__header" }, [
            _c("h4", [_vm._v("Filter results")]),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "filter-clear",
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.onClearAll.apply(null, arguments)
                  }
                }
              },
              [_vm._v("Clear All")]
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "field-set typeahead-container" }, [
            _c(
              "label",
              {
                attrs: { for: "specialties-filter" },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.hideFilterGroup.apply(null, arguments)
                  }
                }
              },
              [_vm._m(0)]
            ),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.specialtyKeyword,
                  expression: "specialtyKeyword"
                }
              ],
              ref: "specialtyField",
              staticClass: "form__input",
              attrs: {
                type: "text",
                name: "specialties-filter",
                id: "specialties-filter",
                "aria-label": "Topic or Specialty",
                placeholder: "",
                autocomplete: "off"
              },
              domProps: { value: _vm.specialtyKeyword },
              on: {
                click: function($event) {
                  return _vm.clickSpecialtyField()
                },
                keydown: _vm.handleKeyDown,
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.specialtyKeyword = $event.target.value
                }
              }
            }),
            _vm._v(" "),
            _vm.showSpecialtyDrop
              ? _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "closable",
                        rawName: "v-closable",
                        value: {
                          exclude: ["specialtyField"],
                          handler: "closeSpecialtyDrop"
                        },
                        expression:
                          "{exclude:['specialtyField'],handler:'closeSpecialtyDrop'}"
                      }
                    ],
                    staticClass: "typeahead typeahead--sidebar"
                  },
                  [
                    _c(
                      "ul",
                      _vm._l(_vm.specialtiesOptions, function(item, index) {
                        return !_vm.filterDropdown(
                          item.value,
                          _vm.specialtyKeyword
                        )
                          ? _c("li", { key: index }, [
                              _c("button", {
                                attrs: { value: item.value },
                                domProps: { innerHTML: _vm._s(item.value) },
                                on: {
                                  click: function($event) {
                                    $event.preventDefault()
                                    return _vm.onUpdateSpecialty(item.value)
                                  }
                                }
                              })
                            ])
                          : _vm._e()
                      }),
                      0
                    ),
                    _vm._v(" "),
                    _vm.showNoResultsMessage(
                      _vm.specialtiesOptions,
                      _vm.specialtyKeyword
                    )
                      ? _c("span", [_vm._v("No results found.")])
                      : _vm._e()
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.Specialties
              ? _c(
                  "button",
                  {
                    staticClass: "field-clear",
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.onClearField(
                          "Specialties",
                          _vm.specialtyKeyword
                        )
                      }
                    }
                  },
                  [
                    _c(
                      "svg",
                      {
                        attrs: {
                          width: "17",
                          height: "16",
                          viewBox: "0 0 17 16",
                          fill: "none",
                          xmlns: "http://www.w3.org/2000/svg"
                        }
                      },
                      [
                        _c("path", {
                          attrs: {
                            d:
                              "M8.80687 7.19313C9.25315 7.63941 9.25315 8.36416 8.80687 8.81044L1.95202 15.6653C1.50575 16.1116 0.780988 16.1116 0.334709 15.6653C-0.11157 15.219 -0.11157 14.4943 0.334709 14.048L6.38268 8L0.33828 1.95202C-0.108 1.50575 -0.108 0.780988 0.33828 0.334709C0.784559 -0.11157 1.50932 -0.11157 1.95559 0.334709L8.81044 7.18956L8.80687 7.19313Z",
                            fill: "#666666"
                          }
                        }),
                        _vm._v(" "),
                        _c("path", {
                          attrs: {
                            d:
                              "M7.33471 8.80687C6.88844 8.36059 6.88844 7.63584 7.33471 7.18956L14.1896 0.33471C14.6358 -0.111569 15.3606 -0.111569 15.8069 0.33471C16.2532 0.780989 16.2532 1.50575 15.8069 1.95203L9.7589 8L15.8033 14.048C16.2496 14.4943 16.2496 15.219 15.8033 15.6653C15.357 16.1116 14.6323 16.1116 14.186 15.6653L7.33114 8.81044L7.33471 8.80687Z",
                            fill: "#666666"
                          }
                        })
                      ]
                    )
                  ]
                )
              : _vm._e()
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "field-set typeahead-container" }, [
            _c(
              "label",
              {
                attrs: { for: "name-filter" },
                on: { click: _vm.hideFilterGroup }
              },
              [_vm._m(1)]
            ),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.nameKeyword,
                  expression: "nameKeyword"
                }
              ],
              ref: "providerField",
              staticClass: "form__input js-typeahead-input",
              attrs: {
                type: "text",
                name: "name-filter",
                id: "name-filter",
                "aria-label": "Provider Name",
                placeholder: "",
                autocomplete: "off"
              },
              domProps: { value: _vm.nameKeyword },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.clickProviderField()
                },
                keydown: _vm.handleKeyDown,
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.nameKeyword = $event.target.value
                }
              }
            }),
            _vm._v(" "),
            _vm.showProviderDrop
              ? _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "closable",
                        rawName: "v-closable",
                        value: {
                          exclude: ["providerField"],
                          handler: "closeProviderDrop"
                        },
                        expression:
                          "{exclude:['providerField'],handler:'closeProviderDrop'}"
                      }
                    ],
                    staticClass: "typeahead typeahead--sidebar"
                  },
                  [
                    _c(
                      "ul",
                      _vm._l(_vm.providersOptions, function(item, index) {
                        return !_vm.filterDropdown(item.value, _vm.nameKeyword)
                          ? _c("li", { key: index }, [
                              _c("button", {
                                attrs: { value: item.value },
                                domProps: { innerHTML: _vm._s(item.value) },
                                on: {
                                  click: function($event) {
                                    $event.preventDefault()
                                    return _vm.onUpdateName(item.value)
                                  }
                                }
                              })
                            ])
                          : _vm._e()
                      }),
                      0
                    ),
                    _vm._v(" "),
                    _vm.showNoResultsMessage(
                      _vm.providersOptions,
                      _vm.nameKeyword
                    )
                      ? _c("span", [_vm._v("No results found.")])
                      : _vm._e()
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.nameKeyword
              ? _c(
                  "button",
                  {
                    staticClass: "field-clear",
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.onClearField("ProviderName", _vm.nameKeyword)
                      }
                    }
                  },
                  [
                    _c(
                      "svg",
                      {
                        attrs: {
                          width: "17",
                          height: "16",
                          viewBox: "0 0 17 16",
                          fill: "none",
                          xmlns: "http://www.w3.org/2000/svg"
                        }
                      },
                      [
                        _c("path", {
                          attrs: {
                            d:
                              "M8.80687 7.19313C9.25315 7.63941 9.25315 8.36416 8.80687 8.81044L1.95202 15.6653C1.50575 16.1116 0.780988 16.1116 0.334709 15.6653C-0.11157 15.219 -0.11157 14.4943 0.334709 14.048L6.38268 8L0.33828 1.95202C-0.108 1.50575 -0.108 0.780988 0.33828 0.334709C0.784559 -0.11157 1.50932 -0.11157 1.95559 0.334709L8.81044 7.18956L8.80687 7.19313Z",
                            fill: "#666666"
                          }
                        }),
                        _vm._v(" "),
                        _c("path", {
                          attrs: {
                            d:
                              "M7.33471 8.80687C6.88844 8.36059 6.88844 7.63584 7.33471 7.18956L14.1896 0.33471C14.6358 -0.111569 15.3606 -0.111569 15.8069 0.33471C16.2532 0.780989 16.2532 1.50575 15.8069 1.95203L9.7589 8L15.8033 14.048C16.2496 14.4943 16.2496 15.219 15.8033 15.6653C15.357 16.1116 14.6323 16.1116 14.186 15.6653L7.33114 8.81044L7.33471 8.80687Z",
                            fill: "#666666"
                          }
                        })
                      ]
                    )
                  ]
                )
              : _vm._e()
          ]),
          _vm._v(" "),
          _c("DistanceFilter"),
          _vm._v(" "),
          _c("div", { staticClass: "field-set" }, [
            _c("div", { staticClass: "input-checkbox on-off" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.toggleAccepting,
                    expression: "toggleAccepting"
                  }
                ],
                attrs: {
                  type: "checkbox",
                  id: "newPatients",
                  name: "newPatients"
                },
                domProps: {
                  checked: Array.isArray(_vm.toggleAccepting)
                    ? _vm._i(_vm.toggleAccepting, null) > -1
                    : _vm.toggleAccepting
                },
                on: {
                  change: [
                    function($event) {
                      var $$a = _vm.toggleAccepting,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 && (_vm.toggleAccepting = $$a.concat([$$v]))
                        } else {
                          $$i > -1 &&
                            (_vm.toggleAccepting = $$a
                              .slice(0, $$i)
                              .concat($$a.slice($$i + 1)))
                        }
                      } else {
                        _vm.toggleAccepting = $$c
                      }
                    },
                    function($event) {
                      $event.preventDefault()
                      return _vm.onNewPatients.apply(null, arguments)
                    }
                  ]
                }
              }),
              _vm._v(" "),
              _c("label", { attrs: { for: "newPatients" } }, [
                _vm._v("Accepting New Patients")
              ])
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "field-set" }, [
            _c("div", { staticClass: "input-checkbox on-off" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.toggleOnline,
                    expression: "toggleOnline"
                  }
                ],
                attrs: {
                  type: "checkbox",
                  id: "onlineScheduling",
                  name: "onlineScheduling"
                },
                domProps: {
                  checked: Array.isArray(_vm.toggleOnline)
                    ? _vm._i(_vm.toggleOnline, null) > -1
                    : _vm.toggleOnline
                },
                on: {
                  change: [
                    function($event) {
                      var $$a = _vm.toggleOnline,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 && (_vm.toggleOnline = $$a.concat([$$v]))
                        } else {
                          $$i > -1 &&
                            (_vm.toggleOnline = $$a
                              .slice(0, $$i)
                              .concat($$a.slice($$i + 1)))
                        }
                      } else {
                        _vm.toggleOnline = $$c
                      }
                    },
                    function($event) {
                      $event.preventDefault()
                      return _vm.onOnlineSched.apply(null, arguments)
                    }
                  ]
                }
              }),
              _vm._v(" "),
              _c("label", { attrs: { for: "onlineScheduling" } }, [
                _vm._v("Offers Online Scheduling")
              ])
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "field-set" }, [
            _c("div", { staticClass: "input-checkbox on-off" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.toggleVirtual,
                    expression: "toggleVirtual"
                  }
                ],
                attrs: {
                  type: "checkbox",
                  id: "virtualVisits",
                  name: "virtualVisits"
                },
                domProps: {
                  checked: Array.isArray(_vm.toggleVirtual)
                    ? _vm._i(_vm.toggleVirtual, null) > -1
                    : _vm.toggleVirtual
                },
                on: {
                  change: [
                    function($event) {
                      var $$a = _vm.toggleVirtual,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 && (_vm.toggleVirtual = $$a.concat([$$v]))
                        } else {
                          $$i > -1 &&
                            (_vm.toggleVirtual = $$a
                              .slice(0, $$i)
                              .concat($$a.slice($$i + 1)))
                        }
                      } else {
                        _vm.toggleVirtual = $$c
                      }
                    },
                    function($event) {
                      $event.preventDefault()
                      return _vm.onVirtualVisits.apply(null, arguments)
                    }
                  ]
                }
              }),
              _vm._v(" "),
              _c("label", { attrs: { for: "virtualVisits" } }, [
                _vm._v("Offers Virtual Visits")
              ])
            ])
          ]),
          _vm._v(" "),
          _vm.enable_map_features
            ? _c("div", { staticClass: "field-set" }, [
                _c(
                  "label",
                  {
                    staticClass: "margin-bottom-md",
                    attrs: { for: "fad-location-input" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.hideFilterGroup.apply(null, arguments)
                      }
                    }
                  },
                  [_vm._m(2)]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "custom-select padding-left" }, [
                  _c("div", { staticClass: "fields-group" }, [
                    _c("div", { staticClass: "input-circle" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.checkedNextAvail,
                            expression: "checkedNextAvail"
                          }
                        ],
                        attrs: {
                          type: "radio",
                          id: "next-availability-3",
                          name: "next-availability",
                          value: "3 day"
                        },
                        domProps: {
                          checked: _vm._q(_vm.checkedNextAvail, "3 day")
                        },
                        on: {
                          change: [
                            function($event) {
                              _vm.checkedNextAvail = "3 day"
                            },
                            function($event) {
                              $event.preventDefault()
                              return _vm.onChangeNextAvailable.apply(
                                null,
                                arguments
                              )
                            }
                          ]
                        }
                      }),
                      _vm._v(" "),
                      _c("label", { attrs: { for: "next-availability-3" } }, [
                        _vm._v("within 3 days")
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "input-circle" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.checkedNextAvail,
                            expression: "checkedNextAvail"
                          }
                        ],
                        attrs: {
                          type: "radio",
                          id: "next-availability-14",
                          name: "next-availability",
                          value: "2 week"
                        },
                        domProps: {
                          checked: _vm._q(_vm.checkedNextAvail, "2 week")
                        },
                        on: {
                          change: [
                            function($event) {
                              _vm.checkedNextAvail = "2 week"
                            },
                            function($event) {
                              $event.preventDefault()
                              return _vm.onChangeNextAvailable.apply(
                                null,
                                arguments
                              )
                            }
                          ]
                        }
                      }),
                      _vm._v(" "),
                      _c("label", { attrs: { for: "next-availability-14" } }, [
                        _vm._v("within 2 weeks")
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "input-circle" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.checkedNextAvail,
                            expression: "checkedNextAvail"
                          }
                        ],
                        attrs: {
                          type: "radio",
                          id: "next-availability-30",
                          name: "next-availability",
                          value: "1 month"
                        },
                        domProps: {
                          checked: _vm._q(_vm.checkedNextAvail, "1 month")
                        },
                        on: {
                          change: [
                            function($event) {
                              _vm.checkedNextAvail = "1 month"
                            },
                            function($event) {
                              $event.preventDefault()
                              return _vm.onChangeNextAvailable.apply(
                                null,
                                arguments
                              )
                            }
                          ]
                        }
                      }),
                      _vm._v(" "),
                      _c("label", { attrs: { for: "next-availability-30" } }, [
                        _vm._v("within 1 month")
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "input-circle" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.checkedNextAvail,
                            expression: "checkedNextAvail"
                          }
                        ],
                        attrs: {
                          type: "radio",
                          id: "next-availability-180",
                          name: "next-availability",
                          value: "6 month"
                        },
                        domProps: {
                          checked: _vm._q(_vm.checkedNextAvail, "6 month")
                        },
                        on: {
                          change: [
                            function($event) {
                              _vm.checkedNextAvail = "6 month"
                            },
                            function($event) {
                              $event.preventDefault()
                              return _vm.onChangeNextAvailable.apply(
                                null,
                                arguments
                              )
                            }
                          ]
                        }
                      }),
                      _vm._v(" "),
                      _c("label", { attrs: { for: "next-availability-180" } }, [
                        _vm._v("within 6 months")
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "input-circle" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.checkedNextAvail,
                            expression: "checkedNextAvail"
                          }
                        ],
                        attrs: {
                          type: "radio",
                          id: "next-availability-0",
                          name: "next-availability",
                          value: "0"
                        },
                        domProps: {
                          checked: _vm._q(_vm.checkedNextAvail, "0")
                        },
                        on: {
                          change: [
                            function($event) {
                              _vm.checkedNextAvail = "0"
                            },
                            function($event) {
                              $event.preventDefault()
                              return _vm.onChangeNextAvailable.apply(
                                null,
                                arguments
                              )
                            }
                          ]
                        }
                      }),
                      _vm._v(" "),
                      _c("label", { attrs: { for: "next-availability-0" } }, [
                        _vm._v("Any")
                      ])
                    ])
                  ])
                ])
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.enable_map_features
            ? _c("div", { staticClass: "field-set" }, [
                _c(
                  "label",
                  {
                    staticClass: "margin-bottom-md",
                    attrs: { for: "fad-location-input" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.hideFilterGroup.apply(null, arguments)
                      }
                    }
                  },
                  [_vm._m(3)]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "custom-select padding-left" }, [
                  _c("div", { staticClass: "fields-group" }, [
                    _c("div", { staticClass: "input-square" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.checkedTimes,
                            expression: "checkedTimes"
                          }
                        ],
                        attrs: {
                          type: "checkbox",
                          id: "preferred_times_filter-1",
                          name: "preferred_times_filter",
                          value: "Before 10am"
                        },
                        domProps: {
                          checked: Array.isArray(_vm.checkedTimes)
                            ? _vm._i(_vm.checkedTimes, "Before 10am") > -1
                            : _vm.checkedTimes
                        },
                        on: {
                          change: [
                            function($event) {
                              var $$a = _vm.checkedTimes,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = "Before 10am",
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    (_vm.checkedTimes = $$a.concat([$$v]))
                                } else {
                                  $$i > -1 &&
                                    (_vm.checkedTimes = $$a
                                      .slice(0, $$i)
                                      .concat($$a.slice($$i + 1)))
                                }
                              } else {
                                _vm.checkedTimes = $$c
                              }
                            },
                            function($event) {
                              $event.preventDefault()
                              return _vm.onChangedTimes.apply(null, arguments)
                            }
                          ]
                        }
                      }),
                      _vm._v(" "),
                      _c(
                        "label",
                        { attrs: { for: "preferred_times_filter-1" } },
                        [_vm._v("Before 10am")]
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "input-square" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.checkedTimes,
                            expression: "checkedTimes"
                          }
                        ],
                        attrs: {
                          type: "checkbox",
                          id: "preferred_times_filter-2",
                          name: "preferred_times_filter",
                          value: "10am - 12pm"
                        },
                        domProps: {
                          checked: Array.isArray(_vm.checkedTimes)
                            ? _vm._i(_vm.checkedTimes, "10am - 12pm") > -1
                            : _vm.checkedTimes
                        },
                        on: {
                          change: [
                            function($event) {
                              var $$a = _vm.checkedTimes,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = "10am - 12pm",
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    (_vm.checkedTimes = $$a.concat([$$v]))
                                } else {
                                  $$i > -1 &&
                                    (_vm.checkedTimes = $$a
                                      .slice(0, $$i)
                                      .concat($$a.slice($$i + 1)))
                                }
                              } else {
                                _vm.checkedTimes = $$c
                              }
                            },
                            function($event) {
                              $event.preventDefault()
                              return _vm.onChangedTimes.apply(null, arguments)
                            }
                          ]
                        }
                      }),
                      _vm._v(" "),
                      _c(
                        "label",
                        { attrs: { for: "preferred_times_filter-2" } },
                        [_vm._v("10am - 12pm")]
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "input-square" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.checkedTimes,
                            expression: "checkedTimes"
                          }
                        ],
                        attrs: {
                          type: "checkbox",
                          id: "preferred_times_filter-3",
                          name: "preferred_times_filter",
                          value: "12pm - 5pm"
                        },
                        domProps: {
                          checked: Array.isArray(_vm.checkedTimes)
                            ? _vm._i(_vm.checkedTimes, "12pm - 5pm") > -1
                            : _vm.checkedTimes
                        },
                        on: {
                          change: [
                            function($event) {
                              var $$a = _vm.checkedTimes,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = "12pm - 5pm",
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    (_vm.checkedTimes = $$a.concat([$$v]))
                                } else {
                                  $$i > -1 &&
                                    (_vm.checkedTimes = $$a
                                      .slice(0, $$i)
                                      .concat($$a.slice($$i + 1)))
                                }
                              } else {
                                _vm.checkedTimes = $$c
                              }
                            },
                            function($event) {
                              $event.preventDefault()
                              return _vm.onChangedTimes.apply(null, arguments)
                            }
                          ]
                        }
                      }),
                      _vm._v(" "),
                      _c(
                        "label",
                        { attrs: { for: "preferred_times_filter-3" } },
                        [_vm._v("12pm - 5pm")]
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "input-square" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.checkedTimes,
                            expression: "checkedTimes"
                          }
                        ],
                        attrs: {
                          type: "checkbox",
                          id: "preferred_times_filter-4",
                          name: "preferred_times_filter",
                          value: "After 5pm"
                        },
                        domProps: {
                          checked: Array.isArray(_vm.checkedTimes)
                            ? _vm._i(_vm.checkedTimes, "After 5pm") > -1
                            : _vm.checkedTimes
                        },
                        on: {
                          change: [
                            function($event) {
                              var $$a = _vm.checkedTimes,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = "After 5pm",
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    (_vm.checkedTimes = $$a.concat([$$v]))
                                } else {
                                  $$i > -1 &&
                                    (_vm.checkedTimes = $$a
                                      .slice(0, $$i)
                                      .concat($$a.slice($$i + 1)))
                                }
                              } else {
                                _vm.checkedTimes = $$c
                              }
                            },
                            function($event) {
                              $event.preventDefault()
                              return _vm.onChangedTimes.apply(null, arguments)
                            }
                          ]
                        }
                      }),
                      _vm._v(" "),
                      _c(
                        "label",
                        { attrs: { for: "preferred_times_filter-4" } },
                        [_vm._v("After 5pm")]
                      )
                    ])
                  ])
                ])
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "field-set" }, [
            _c(
              "label",
              {
                staticClass: "margin-bottom-md",
                attrs: { for: "fad-location-input" },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.hideFilterGroup.apply(null, arguments)
                  }
                }
              },
              [_vm._m(4)]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "custom-select padding-left" }, [
              _c("div", { staticClass: "fields-group" }, [
                _c("div", { staticClass: "input-square" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.checkedGenders,
                        expression: "checkedGenders"
                      }
                    ],
                    attrs: {
                      type: "checkbox",
                      id: "female",
                      name: "genders_filter",
                      value: "Female"
                    },
                    domProps: {
                      checked: Array.isArray(_vm.checkedGenders)
                        ? _vm._i(_vm.checkedGenders, "Female") > -1
                        : _vm.checkedGenders
                    },
                    on: {
                      change: [
                        function($event) {
                          var $$a = _vm.checkedGenders,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = "Female",
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                (_vm.checkedGenders = $$a.concat([$$v]))
                            } else {
                              $$i > -1 &&
                                (_vm.checkedGenders = $$a
                                  .slice(0, $$i)
                                  .concat($$a.slice($$i + 1)))
                            }
                          } else {
                            _vm.checkedGenders = $$c
                          }
                        },
                        function($event) {
                          $event.preventDefault()
                          return _vm.onChangeGenders.apply(null, arguments)
                        }
                      ]
                    }
                  }),
                  _vm._v(" "),
                  _c("label", { attrs: { for: "female" } }, [_vm._v("Female")])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "input-square" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.checkedGenders,
                        expression: "checkedGenders"
                      }
                    ],
                    attrs: {
                      type: "checkbox",
                      id: "male",
                      name: "genders_filter",
                      value: "Male"
                    },
                    domProps: {
                      checked: Array.isArray(_vm.checkedGenders)
                        ? _vm._i(_vm.checkedGenders, "Male") > -1
                        : _vm.checkedGenders
                    },
                    on: {
                      change: [
                        function($event) {
                          var $$a = _vm.checkedGenders,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = "Male",
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                (_vm.checkedGenders = $$a.concat([$$v]))
                            } else {
                              $$i > -1 &&
                                (_vm.checkedGenders = $$a
                                  .slice(0, $$i)
                                  .concat($$a.slice($$i + 1)))
                            }
                          } else {
                            _vm.checkedGenders = $$c
                          }
                        },
                        function($event) {
                          $event.preventDefault()
                          return _vm.onChangeGenders.apply(null, arguments)
                        }
                      ]
                    }
                  }),
                  _vm._v(" "),
                  _c("label", { attrs: { for: "male" } }, [_vm._v("Male")])
                ])
              ])
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "field-set" }, [
            _c(
              "label",
              {
                staticClass: "margin-bottom-md",
                attrs: { for: "fad-location-input" },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.hideFilterGroup.apply(null, arguments)
                  }
                }
              },
              [_vm._m(5)]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "custom-select padding-left" }, [
              _vm.sortedLanguages && _vm.sortedLanguages.length
                ? _c(
                    "div",
                    { staticClass: "fields-group" },
                    [
                      _vm._l(_vm.sortedLanguages, function(language, index) {
                        return _c(
                          "div",
                          {
                            staticClass: "input-square",
                            class: { hide: index > 4 }
                          },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.checkedLang,
                                  expression: "checkedLang"
                                }
                              ],
                              attrs: {
                                type: "checkbox",
                                id: "lang-" + language.value,
                                name: "languages_filter"
                              },
                              domProps: {
                                value: language.value,
                                checked: Array.isArray(_vm.checkedLang)
                                  ? _vm._i(_vm.checkedLang, language.value) > -1
                                  : _vm.checkedLang
                              },
                              on: {
                                change: [
                                  function($event) {
                                    var $$a = _vm.checkedLang,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? true : false
                                    if (Array.isArray($$a)) {
                                      var $$v = language.value,
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          (_vm.checkedLang = $$a.concat([$$v]))
                                      } else {
                                        $$i > -1 &&
                                          (_vm.checkedLang = $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1)))
                                      }
                                    } else {
                                      _vm.checkedLang = $$c
                                    }
                                  },
                                  function($event) {
                                    $event.preventDefault()
                                    return _vm.onChangeLang.apply(
                                      null,
                                      arguments
                                    )
                                  }
                                ]
                              }
                            }),
                            _vm._v(" "),
                            _c(
                              "label",
                              { attrs: { for: "lang-" + language.value } },
                              [_vm._v(_vm._s(language.value))]
                            )
                          ]
                        )
                      }),
                      _vm._v(" "),
                      _vm.sortedLanguages && _vm.sortedLanguages.length > 5
                        ? _c(
                            "button",
                            {
                              staticClass: "lnk-button show-more",
                              attrs: { "data-filterId": "langs" },
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  return _vm.showFilterOptionsList($event)
                                }
                              }
                            },
                            [
                              _vm._v("Show more"),
                              _c("i", { staticClass: "fas fa-chevron-up" })
                            ]
                          )
                        : _vm._e()
                    ],
                    2
                  )
                : _vm._e()
            ])
          ])
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "fad-filters__sticky-bar" }, [
        _c(
          "button",
          {
            staticClass: "fad-filters__update",
            on: {
              click: function($event) {
                $event.preventDefault()
                return _vm.closeMobileFilters.apply(null, arguments)
              }
            }
          },
          [_vm._v("Update Search")]
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "filter-clear",
            on: {
              click: function($event) {
                $event.preventDefault()
                return _vm.onClearAll.apply(null, arguments)
              }
            }
          },
          [_vm._v("Clear All")]
        )
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", [_c("b", [_vm._v("Specialty")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", [_c("b", [_vm._v("Provider Name")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", [_c("b", [_vm._v("Next Availability")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", [_c("b", [_vm._v("Preferred Appointment Times")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", [_c("b", [_vm._v("Provider's Gender")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", [_c("b", [_vm._v("Languages Spoken")])])
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-d9b19f18", { render: render, staticRenderFns: staticRenderFns })
  }
}