var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.scheduleSlot
    ? _c(
        "div",
        {
          staticClass: "schedule-calendar__time",
          class: { deactive: !_vm.isActive, online: _vm.scheduleSlot.Virtual }
        },
        [
          _vm.isActive
            ? _c("a", { attrs: { href: _vm.slotUrl } }, [
                _vm._v(_vm._s(_vm.slotLabel))
              ])
            : _vm._e()
        ]
      )
    : _c("div", { staticClass: "schedule-calendar__time deactive" }, [
        _vm._v("---")
      ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-297310c5", { render: render, staticRenderFns: staticRenderFns })
  }
}