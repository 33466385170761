<template>
  <div>
    <div class="category-filter">
      <h4 v-if="filters" class="clear-filters">
        Filter Results
        <button @click.prevent="onResetFilter">Clear Filters</button>
      </h4>
      <div v-if="!filters" class="no-results light">
        <h4>Loading Filters...</h4>
      </div>
      <div v-for="filter in filters" :key="filter.id">
        <div v-if="filter.type !== 'reasons'">
          <div
            :class="['filter-block', filter.type, 'category-list']"
            v-if="filter.data.length > 0"
          >
            <h4 v-if="filter.label">
              {{ filter.label }}
            </h4>
            <ul class="form-group" id="formCheckList">
              <li class="form-check" v-for="(item, index) in filter.data" :key="item.id" v-bind:class="{hide: index > 4}">
                <div class="checkbox">
                  <input
                    :value="item.id"
                    :id="'chk_' + item.id + '_' + filter.type"
                    :name="'chk_' + item.id + '_' + filter.type"
                    @change="onTickChange()"
                    v-model="item.checked"
                    type="checkbox"
                  />
                  <label :for="'chk_' + item.id + '_' + filter.type">
                    <div v-if="item.iconUrl" class="icon">
                      <img :src="item.iconUrl" :alt="item.label" />
                    </div>
                    <div v-if="!item.iconUrl" class="dot" :style="{backgroundColor:item.color}"></div>
                    {{ item.label }} ({{item.count}})
                  </label>
                </div>
              </li>
              <li><small class="form-helper more-trigger" v-if="filter.data && filter.data.length > 5">
              <a class="form-more show-more" v-on:click.stop="showFilterOptionsList($event)" ref="el" :data-filter-id="filter.id">See More</a>
            </small></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "CategoryFilter",
  computed: {
    ...mapGetters(["theme"]),
  },
  methods: {
    ...mapActions(["fetchResults", "fetchFilters"]),
    showFilterOptions(event) {
      let thisClicked = event.currentTarget;
      thisClicked.classList.toggle('expanded');
      let filterId = thisClicked.dataset.filterId;
      if(filterId){
        let parentElem = document.getElementById(filterId);
        if(parentElem){
          let checkboxes = parentElem.querySelectorAll('.form-check');
          if(checkboxes){
            if(thisClicked.classList.contains('expanded')){
              thisClicked.innerText = 'SHOW LESS';
              for(let chk of checkboxes){
                chk.classList.remove('hide');
              }
            }
            else{
              thisClicked.innerText = 'SHOW MORE';
              for(let i in checkboxes){
                if(i > 4){
                  let chk = checkboxes[i];
                  chk.classList.add('hide');
                }
              }
            }
          }
        }
      }
    },
    showFilterOptionsList(event) {
      let thisClicked = event.currentTarget;
      thisClicked.classList.toggle('expanded');
      let filterId = thisClicked.dataset.filterId;
      if(filterId){
        let parentElem = thisClicked.closest('#formCheckList');
        if(parentElem){
          let checkboxes = parentElem.querySelectorAll('.form-check');
          if(checkboxes){
            if(thisClicked.classList.contains('expanded')){
              thisClicked.innerText = 'See Less';
              for(let chk of checkboxes){
                chk.classList.remove('hide');
              }
            }
            else{
              thisClicked.innerText = 'See More';
              for(let i in checkboxes){
                if(i > 4){
                  let chk = checkboxes[i];
                  chk.classList.add('hide');
                }
              }
            }
          }
        }
      }
    },
    getSpecialtiesFromUrl(filters) {
      const comp = this;
      const urlParams = new URLSearchParams(window.location.search);
      const spec = urlParams.get("specialty");
      if (spec) {
        let specs = spec.split(",");
        if (specs && spec.length > 0) {
          for (let filter of filters) {
            for (let item of filter.data) {
              if (specs.includes(item.id)) {
                item.checked = true;
              }
            }
          }
          comp.onTickChange();
        }
      }
    },
    getLocationTypeFromUrl(filters) {
      var hiddenValues = document.getElement(".form-check.hide");
      const comp = this;
      const urlParams = new URLSearchParams(window.location.search);
      const locType = urlParams.get("type");
      if (locType) {
        var typeFilters = filters.find((item) => {
          return null;
        });

        if (typeFilters) {
          for (let typeFilter of typeFilters.data) {
            if (typeFilter.id == locType) {
              item.checked = true;
            }
          }
          comp.onTickChange();
        }
      }
    },
    onTickChange() {
      const params = new URLSearchParams(location.search);

      let specList = [];
      let typeList = [];
      this.filters.forEach((filter) => {
        const type = filter.type.charAt(0).toUpperCase() + filter.type.slice(1);
        let values = [];
        filter.data.forEach((item) => {
          if (item.checked) {
            if (type == "Categories") {
              typeList.push(item.id);
            }
            if (type == "Specialties") {
              specList.push(item.id);
            }
            values.push(item.id);
          }
        });
        this.$store.commit(type, values.join(","));
      });

      if (specList.length > 0) {
        params.set("specialty", specList.join());
      } else {
        params.delete("specialty");
      }
      if (typeList.length > 0) {
        params.set("type", typeList.join());
      } else {
        params.delete("type");
      }
      window.history.replaceState({}, "", `${location.pathname}?${params}`);
      this.fetchResults();
    },
    onResetFilter() {
      this.filters.forEach((filter) => {
        const type = filter.type.charAt(0).toUpperCase() + filter.type.slice(1);
        filter.data.forEach((item) => {
          if (item.checked) {
            item.checked = false;
          }
        });
        this.$store.commit(type, null);
        this.fetchResults();
      });
    },
  },
  created() {
    this.$store.subscribe((mutation) => {
      if (mutation.type === "filters") {
        const filters = mutation.payload;
        this.filters = filters;
      }
    });
  },
  mounted() {},
  data() {
    return {
      filters: null,
      visibleFilters: null,
    };
  },
  watch: {
    filters: function () {},
  },
};
</script>