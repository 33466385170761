<!-- COMP-53 Doctors - Search Filters -->
<template>
  <div>

    <div class="fad-filters js-fad-filter">
        <button @click.prevent="closeMobileFilters" class="fad-filters__close">
          Cancel
          <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.80687 7.68102C9.25315 8.13406 9.25315 8.8698 8.80687 9.32284L1.95202 16.2815C1.50575 16.7346 0.780988 16.7346 0.334709 16.2815C-0.11157 15.8285 -0.11157 15.0928 0.334709 14.6397L6.38268 8.50012L0.33828 2.36051C-0.108 1.90747 -0.108 1.17173 0.33828 0.718687C0.784559 0.265646 1.50932 0.265646 1.95559 0.718687L8.81044 7.6774L8.80687 7.68102Z" fill="white"/>
            <path d="M7.33471 9.31922C6.88844 8.86618 6.88844 8.13044 7.33471 7.67739L14.1896 0.718688C14.6358 0.265647 15.3606 0.265647 15.8069 0.718688C16.2532 1.17173 16.2532 1.90747 15.8069 2.36051L9.7589 8.50012L15.8033 14.6397C16.2496 15.0928 16.2496 15.8285 15.8033 16.2815C15.357 16.7346 14.6323 16.7346 14.186 16.2815L7.33114 9.32284L7.33471 9.31922Z" fill="white"/>
          </svg>
        </button>
        <form class="fad-filters__form js-fad-filters-form" autocomplete="off">
          <div class="fad-filters__header">
            <h4>Filter results</h4>
            <button class="filter-clear" @click.prevent="onClearAll">Clear All</button>
          </div>
          <div class="field-set typeahead-container">
            <label @click.prevent="hideFilterGroup" for="specialties-filter"><span><b>Specialty</b></span></label>
            <input
              v-model="specialtyKeyword"
              type="text" 
              ref="specialtyField"
              name="specialties-filter" 
              id="specialties-filter" 
              class="form__input"
              aria-label="Topic or Specialty"
              placeholder=""
              autocomplete="off"
              @click="clickSpecialtyField()"
              @keydown="handleKeyDown"
            >
            <div class="typeahead typeahead--sidebar" v-closable="{exclude:['specialtyField'],handler:'closeSpecialtyDrop'}" v-if="showSpecialtyDrop">
                <ul>
                    <li v-for="(item, index) in specialtiesOptions" :key="index" v-if="!filterDropdown(item.value, specialtyKeyword)">
                        <button @click.prevent="onUpdateSpecialty(item.value)" :value="item.value" v-html="item.value"></button>
                    </li>
                </ul>
                <span v-if="showNoResultsMessage(specialtiesOptions, specialtyKeyword)">No results found.</span>
            </div>
            <button @click.prevent="onClearField('Specialties', specialtyKeyword)" class="field-clear" v-if="Specialties">
              <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.80687 7.19313C9.25315 7.63941 9.25315 8.36416 8.80687 8.81044L1.95202 15.6653C1.50575 16.1116 0.780988 16.1116 0.334709 15.6653C-0.11157 15.219 -0.11157 14.4943 0.334709 14.048L6.38268 8L0.33828 1.95202C-0.108 1.50575 -0.108 0.780988 0.33828 0.334709C0.784559 -0.11157 1.50932 -0.11157 1.95559 0.334709L8.81044 7.18956L8.80687 7.19313Z" fill="#666666"/>
                <path d="M7.33471 8.80687C6.88844 8.36059 6.88844 7.63584 7.33471 7.18956L14.1896 0.33471C14.6358 -0.111569 15.3606 -0.111569 15.8069 0.33471C16.2532 0.780989 16.2532 1.50575 15.8069 1.95203L9.7589 8L15.8033 14.048C16.2496 14.4943 16.2496 15.219 15.8033 15.6653C15.357 16.1116 14.6323 16.1116 14.186 15.6653L7.33114 8.81044L7.33471 8.80687Z" fill="#666666"/>
              </svg>
            </button>
          </div>

          <!-- PROVIDER NAME -->
          <div class="field-set typeahead-container">
            <label @click="hideFilterGroup" for="name-filter"><span><b>Provider Name</b></span></label>
            <input 
              v-model="nameKeyword"
              ref="providerField"
              type="text" 
              name="name-filter" 
              id="name-filter" 
              class="form__input js-typeahead-input"
              aria-label="Provider Name"
              placeholder=""
              autocomplete="off"
              @click.prevent="clickProviderField()"
              @keydown="handleKeyDown"
            >
            <div class="typeahead typeahead--sidebar" v-closable="{exclude:['providerField'],handler:'closeProviderDrop'}" v-if="showProviderDrop">
                <ul>
                    <li v-for="(item, index) in providersOptions" :key="index" v-if="!filterDropdown(item.value, nameKeyword)">
                        <button @click.prevent="onUpdateName(item.value)" :value="item.value" v-html="item.value"></button>
                    </li>
                </ul>
                <span v-if="showNoResultsMessage(providersOptions, nameKeyword)">No results found.</span>
            </div>
            <button @click.prevent="onClearField('ProviderName', nameKeyword)" class="field-clear" v-if="nameKeyword">
              <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.80687 7.19313C9.25315 7.63941 9.25315 8.36416 8.80687 8.81044L1.95202 15.6653C1.50575 16.1116 0.780988 16.1116 0.334709 15.6653C-0.11157 15.219 -0.11157 14.4943 0.334709 14.048L6.38268 8L0.33828 1.95202C-0.108 1.50575 -0.108 0.780988 0.33828 0.334709C0.784559 -0.11157 1.50932 -0.11157 1.95559 0.334709L8.81044 7.18956L8.80687 7.19313Z" fill="#666666"/>
                <path d="M7.33471 8.80687C6.88844 8.36059 6.88844 7.63584 7.33471 7.18956L14.1896 0.33471C14.6358 -0.111569 15.3606 -0.111569 15.8069 0.33471C16.2532 0.780989 16.2532 1.50575 15.8069 1.95203L9.7589 8L15.8033 14.048C16.2496 14.4943 16.2496 15.219 15.8033 15.6653C15.357 16.1116 14.6323 16.1116 14.186 15.6653L7.33114 8.81044L7.33471 8.80687Z" fill="#666666"/>
              </svg>
            </button>
          </div>

          <!-- LOCATION -->
          <DistanceFilter />

          <!-- INSURANCE 
          <div class="field-set typeahead-container">
            <label @click.prevent="hideFilterGroup" for="insurance-filter"><span><b>Insurance</b></span></label>
            <input 
              v-model="insuranceKeyword"
              ref="insuranceField"
              type="text" 
              name="insurance-filter" 
              id="insurance-filter" 
              class="form__input js-typeahead-input"
              aria-label="Insurance"
              placeholder=""
              autocomplete="off"
              @click.prevent="clickInsuranceField()"
            >
            <div class="typeahead typeahead--sidebar" v-closable="{exclude:['insuranceField'],handler:'closeInsuranceDrop'}" v-if="showInsuranceDrop">
                <ul>
                    <li v-for="(item, index) in insuranceOptions" :key="index" v-if="!filterDropdown(item.value, insuranceKeyword)">
                        <button @click.prevent="onUpdateInsurance(item.value)" :value="item.value" v-html="item.value"></button>
                    </li>
                </ul>
                <span v-if="showNoResultsMessage(insuranceOptions, insuranceKeyword)">No results found.</span>
            </div>
            <button @click.prevent="onClearField('Insurances', insuranceKeyword)" class="field-clear" v-if="showInsuranceDrop">
              <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.80687 7.19313C9.25315 7.63941 9.25315 8.36416 8.80687 8.81044L1.95202 15.6653C1.50575 16.1116 0.780988 16.1116 0.334709 15.6653C-0.11157 15.219 -0.11157 14.4943 0.334709 14.048L6.38268 8L0.33828 1.95202C-0.108 1.50575 -0.108 0.780988 0.33828 0.334709C0.784559 -0.11157 1.50932 -0.11157 1.95559 0.334709L8.81044 7.18956L8.80687 7.19313Z" fill="#666666"/>
                <path d="M7.33471 8.80687C6.88844 8.36059 6.88844 7.63584 7.33471 7.18956L14.1896 0.33471C14.6358 -0.111569 15.3606 -0.111569 15.8069 0.33471C16.2532 0.780989 16.2532 1.50575 15.8069 1.95203L9.7589 8L15.8033 14.048C16.2496 14.4943 16.2496 15.219 15.8033 15.6653C15.357 16.1116 14.6323 16.1116 14.186 15.6653L7.33114 8.81044L7.33471 8.80687Z" fill="#666666"/>
              </svg>
            </button>
          </div>
        -->

          <!-- New Patients -->
          <div class="field-set">
            <div class="input-checkbox on-off">
              <input @change.prevent="onNewPatients" v-model="toggleAccepting" type="checkbox" id="newPatients" name="newPatients" />
              <label for="newPatients">Accepting New Patients</label>
            </div>
          </div>

          <div class="field-set">
            <div class="input-checkbox on-off">
              <input @change.prevent="onOnlineSched" v-model="toggleOnline" type="checkbox" id="onlineScheduling" name="onlineScheduling" />
              <label for="onlineScheduling">Offers Online Scheduling</label>
            </div>
          </div>

          <div class="field-set">
            <div class="input-checkbox on-off">
              <input @change.prevent="onVirtualVisits" v-model="toggleVirtual" type="checkbox" id="virtualVisits" name="virtualVisits" />
              <label for="virtualVisits">Offers Virtual Visits</label>
            </div>
          </div>

          <!-- NEXT AVAILABILITY -->
          <div class="field-set" v-if="enable_map_features">
            <label @click.prevent="hideFilterGroup" for="fad-location-input" class="margin-bottom-md"><span><b>Next Availability</b></span></label>

            <div class="custom-select padding-left">
              <div class="fields-group">
                <div class="input-circle">
                  <input @change.prevent="onChangeNextAvailable" v-model="checkedNextAvail" type="radio" id="next-availability-3" name="next-availability" value="3 day" />
                  <label for="next-availability-3">within 3 days</label>
                </div>

                <div class="input-circle">
                  <input @change.prevent="onChangeNextAvailable" v-model="checkedNextAvail" type="radio" id="next-availability-14" name="next-availability" value="2 week" />
                  <label for="next-availability-14">within 2 weeks</label>
                </div>

                <div class="input-circle">
                  <input @change.prevent="onChangeNextAvailable" v-model="checkedNextAvail" type="radio" id="next-availability-30" name="next-availability" value="1 month" />
                  <label for="next-availability-30">within 1 month</label>
                </div>

                <div class="input-circle">
                  <input @change.prevent="onChangeNextAvailable" v-model="checkedNextAvail" type="radio" id="next-availability-180" name="next-availability" value="6 month" />
                  <label for="next-availability-180">within 6 months</label>
                </div>

                <div class="input-circle">
                  <input @change.prevent="onChangeNextAvailable" v-model="checkedNextAvail" type="radio" id="next-availability-0" name="next-availability" value="0" />
                  <label for="next-availability-0">Any</label>
                </div>
              </div>
            </div>
          </div>

          <!-- PREFERRED APPOINTMENT TIMES -->
          <div class="field-set" v-if="enable_map_features">
            <label @click.prevent="hideFilterGroup" for="fad-location-input" class="margin-bottom-md"><span><b>Preferred Appointment Times</b></span></label>

            <div class="custom-select padding-left">
              
              <div class="fields-group">
                <div class="input-square">
                  <input type="checkbox" @change.prevent="onChangedTimes" id="preferred_times_filter-1" name="preferred_times_filter" v-model="checkedTimes" value="Before 10am" />
                  <label for="preferred_times_filter-1">Before 10am</label>
                </div>

                <div class="input-square">
                  <input type="checkbox" @change.prevent="onChangedTimes" id="preferred_times_filter-2" name="preferred_times_filter" v-model="checkedTimes" value="10am - 12pm" />
                  <label for="preferred_times_filter-2">10am - 12pm</label>
                </div>

                <div class="input-square">
                  <input type="checkbox" @change.prevent="onChangedTimes" id="preferred_times_filter-3" name="preferred_times_filter" v-model="checkedTimes" value="12pm - 5pm" />
                  <label for="preferred_times_filter-3">12pm - 5pm</label>
                </div>

                <div class="input-square">
                  <input type="checkbox" @change.prevent="onChangedTimes" id="preferred_times_filter-4" name="preferred_times_filter" v-model="checkedTimes" value="After 5pm" />
                  <label for="preferred_times_filter-4">After 5pm</label>
                </div>

              </div>
            </div>
          </div>

          <!-- PROVIDER'S GENDER -->
          <div class="field-set">
            <label @click.prevent="hideFilterGroup" for="fad-location-input" class="margin-bottom-md"><span><b>Provider's Gender</b></span></label>

            <div class="custom-select padding-left">
              
              <div class="fields-group">
                <div class="input-square">
                  <input @change.prevent="onChangeGenders" type="checkbox" v-model="checkedGenders" id="female" name="genders_filter" value="Female" />
                  <label for="female">Female</label>
                </div>

                <div class="input-square">
                  <input @change.prevent="onChangeGenders" type="checkbox" v-model="checkedGenders" id="male" name="genders_filter" value="Male" />
                  <label for="male">Male</label>
                </div>
              </div>
            </div>
          </div>

          
          <!-- Languages Spoken -->
          <div class="field-set">
            <label @click.prevent="hideFilterGroup" for="fad-location-input" class="margin-bottom-md"><span><b>Languages Spoken</b></span></label>

            <div class="custom-select padding-left">
              <div v-if="sortedLanguages && sortedLanguages.length" class="fields-group">
                <div v-for="(language, index) in sortedLanguages" class="input-square" v-bind:class="{hide: index > 4}">
                  <input @change.prevent="onChangeLang" type="checkbox" :id="'lang-'+language.value" name="languages_filter" v-model="checkedLang" :value="language.value" />
                  <label :for="'lang-'+language.value">{{language.value}}</label>
                </div>
                <button v-if="sortedLanguages && sortedLanguages.length > 5" @click.prevent="showFilterOptionsList($event)" class="lnk-button show-more" data-filterId="langs">Show more<i class="fas fa-chevron-up"></i></button>
              </div>
            </div>
          </div>

        </form>

        <div class="fad-filters__sticky-bar">
          <button @click.prevent="closeMobileFilters" class="fad-filters__update">Update Search</button>
          <button @click.prevent="onClearAll" class="filter-clear">Clear All</button>
        </div>
    </div>

  </div>
</template>

<script>
import $ from 'jquery';
import Vue from "vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import DistanceFilter from './DistanceFilter.vue'

let handleOutsideClick
Vue.directive('closable', {
  bind (el, binding, vnode) {
    // Here's the click/touchstart handler
    const handleOutsideClick = (e) => {
      e.stopPropagation()
      // Get the handler method name and the exclude array
      // from the object used in v-closable
      const { handler, exclude } = binding.value
      // This variable indicates if the clicked element is excluded
      let clickedOnExcludedEl = false
      exclude.forEach(refName => {
        // We only run this code if we haven't detected
        // any excluded element yet
        if (!clickedOnExcludedEl) {
          // Get the element using the reference name
          const excludedEl = vnode.context.$refs[refName]
          // See if this excluded element
          // is the same element the user just clicked on
          clickedOnExcludedEl = excludedEl && excludedEl.contains(e.target)
        }
      })
      // We check to see if the clicked element is not
      // the dialog element and not excluded
      if (!el.contains(e.target) && !clickedOnExcludedEl) {
        // If the clicked element is outside the dialog
        // and not the button, then call the outside-click handler
        // from the same component this directive is used in
        vnode.context[handler]()
      }
    }
    // Register click/touchstart event listeners on the whole page
    el.__vueClickOutside__ = handleOutsideClick;
    document.addEventListener('click', handleOutsideClick)
    document.addEventListener('touchstart', handleOutsideClick)
  },
  unbind (el) {
    // If the element that has v-closable is removed, then
    // unbind click/touchstart listeners from the whole page
    document.removeEventListener('click', el.__vueClickOutside__);
    document.removeEventListener('touchstart', el.__vueClickOutside__);
    delete el.__vueClickOutside__;
  }
})

export default {
  name: "DoctorSearchFilters",
  data() {
    return {
      selects: null,
      selectTypes: [],
      toggleAccepting: null,
      toggleVirtual: null,
      toggleOnline: null,
      checkedNextAvail: null,
      checkedGenders: [],
      checkedTimes: [],
      checkedLang: [],
      specialtiesFilter: '',
      queryString: '',
      specialtyQueryString: '',
      driveTimeTooltip: false,
      specialtyKeyword: '',
      nameKeyword: '',
      insuranceKeyword: '',
      showSpecialtyDrop: false,
      showInsuranceDrop: false,
      showProviderDrop: false,
    }
  },
  computed: {
    ...mapGetters([  
      "results",
      "Keyword",
      "Insurances",
      "PreferredTimes",
      "AcceptingNewPatients",
      "NextAvailability",
      "Genders",
      "OffersOnlineScheduling",
      "OffersVirtualVisits",
      "Languages",
      "Specialties",
      "ProviderName",
      "filterRemoved",
      "clearAll",
      "ActiveFilters",
      "HasUrlParams",
      "showInsurancesBlock",
      "enable_map_features"
    ]),
    sortedLanguages() {
      // Sort the data by the "count" property in descending order
      const facets = this.results.facets;
      if(facets != null) {
        const langObj = facets.find(i => {
          return i.name === 'languages'
        });
        const langsArr = langObj ? langObj.terms : [];
        const sortedLangsArr = langsArr.slice().sort((a, b) => b.count - a.count);
        return sortedLangsArr;
      }
    },
    specialtiesOptions() {
      const facets = this.results.facets;
      if(facets != null) {
        const specialtiesObj = facets.find(i => {
          return i.name === 'specialties'
        });
        const specialtiesArr = specialtiesObj ? specialtiesObj.terms : [];
        return specialtiesArr;
      }
    },
    providersOptions() {
      const facets = this.results.facets;
      if(facets != null) {
        const providersObj = facets.find(i => {
          return i.name === 'provider.name'
        });
        const providersArr = providersObj ? providersObj.terms : [];
        return providersArr;
      }
    },
    insuranceOptions() {
      const facets = this.results.facets;
      if(facets != null) {
        const insuranceObj = facets.find(i => {
          return i.name === 'insurance'
        });
        const insuranceArr = insuranceObj ? insuranceObj.terms : [];
        return insuranceArr;
      }
    },
    specialtySearchResults() {
        return this.$store.state.typeahead.SpecialtySearchResults;
    },
    providerSearchResults() {
        return this.$store.state.typeahead.NameSearchResults;
    },
    insuranceSearchResults() {
        return this.$store.state.typeahead.InsuranceSearchResults;
    },
  },
  components: {
    DistanceFilter
  },
  methods: {
    ...mapActions(["fetchResults", "setUrlParams"]),
    ...mapMutations(["addOrUpdateFilter", "removeFilter", "removeAllFilters", "removeLocationCookie"]),
    onChangeNextAvailable() {
      this.$store.commit("NextAvailability", this.checkedNextAvail);
      this.toggleFilter("NextAvailability", this.checkedNextAvail);
      this.fetchResults();
    },
    onChangedTimes(){
      this.$store.commit("PreferredTimes", this.checkedTimes);
      this.toggleFilter( "PreferredTimes", this.checkedTimes );
      this.fetchResults();
    },
    onChangeGenders(e) {
      this.$store.commit("Genders", this.checkedGenders);
      this.toggleFilter( "Genders", this.checkedGenders );
      this.fetchResults();
    },
    onNewPatients(e) {
      this.$store.commit("AcceptingNewPatients", this.toggleAccepting);
      if (this.toggleAccepting === true) {
        this.toggleFilter( "AcceptingNewPatients", this.toggleAccepting );
      } else {
        this.removeFilter("AcceptingNewPatients");
      }
      this.fetchResults();
    },
    onOnlineSched(e) {
      this.$store.commit("OffersOnlineScheduling", this.toggleOnline);
      if (this.toggleOnline === true) {
        this.toggleFilter( "OffersOnlineScheduling", this.toggleOnline );
      } else {
        this.removeFilter("OffersOnlineScheduling");
      }
      this.fetchResults();
    },
    onVirtualVisits(e) {
      this.$store.commit("OffersVirtualVisits", this.toggleVirtual);
      if (this.toggleVirtual === true) {
        this.toggleFilter( "OffersVirtualVisits", this.toggleVirtual );
      } else {
        this.removeFilter("OffersVirtualVisits");
      }
      this.fetchResults();
    },
    onChangeLang() {
      this.$store.commit("Languages", this.checkedLang);
      this.toggleFilter( "Languages", this.checkedLang );
      this.fetchResults();
    },
    onSearchChange: _.debounce(function(query, category) {
        if (query.length < 3) return;
        this.$store.dispatch('fetchCatSearchResults', {param1: query, param2: category});
    }, 500),
    onUpdateSpecialty(value){
        this.selected = value;
        this.specialtyKeyword = this.selected;
        this.showSpecialtyDrop = false;
        this.$store.commit("Specialties", this.specialtyKeyword);
        this.toggleFilter( "Specialties", this.specialtyKeyword );
        this.fetchResults();
    },
    onUpdateName(value){
        this.selected = value;
        this.nameKeyword = this.selected;
        this.showProviderDrop = false;
        this.$store.commit("ProviderName", this.nameKeyword);
        this.toggleFilter( "ProviderName", this.nameKeyword );
        this.fetchResults();
    },
    onUpdateInsurance(value){
        this.selected = value;
        this.insuranceKeyword = this.selected;
        this.showInsuranceDrop = false;
        this.$store.commit("Insurances", this.insuranceKeyword);
        this.$store.commit("showInsurancesBlock", true);
        this.toggleFilter( "Insurances", this.insuranceKeyword );
        this.fetchResults();
    },
    clickSpecialtyField(){
      this.showSpecialtyDrop = true;
      if(this.specialtyKeyword){
        this.specialtyKeyword = '';
        this.$store.commit("Specialties", null);
        this.removeFilter( "Specialties");
        this.fetchResults();
      }
    },
    clickProviderField(){
      this.showProviderDrop = true;
      if(this.nameKeyword){
        this.nameKeyword = '';
        this.$store.commit("ProviderName", null);
        this.removeFilter( "ProviderName");
        this.fetchResults();
      }
    },
    clickInsuranceField(){
      this.showInsuranceDrop = true;
      if(this.insuranceKeyword){
        this.insuranceKeyword = '';
        this.$store.commit("Insurances", null);
        this.removeFilter( "Insurances");
        this.fetchResults();
      }
    },
    closeSpecialtyDrop(){
      this.showSpecialtyDrop = false;
    },
    closeProviderDrop(){
      this.showProviderDrop = false;
    },
    closeInsuranceDrop(){
      this.showInsuranceDrop = false;
    },
    handleKeyDown(event) {
      if (event.key === 'Enter') {
        event.preventDefault();
      }
    },
    onClearField(key, model){
      const comp = this;
      const urlParams = new URLSearchParams(window.location.search);
      if (key && model) {
        model = null;
        switch (key) {
          case 'ProviderName':
            comp.nameKeyword = '';
            if(urlParams.get('ProviderName')){
                urlParams.delete('ProviderName');
                window.history.replaceState({}, '', `${location.pathname}?${urlParams}`);
            }
            break;
          case 'Specialties':
            comp.specialtyKeyword = '';
            if(urlParams.get('SpecialtiesFilter')){
                urlParams.delete('SpecialtiesFilter');
                window.history.replaceState({}, '', `${location.pathname}?${urlParams}`);
            }
            break;
          case 'Insurances':
            comp.insuranceKeyword = '';
            this.$store.commit("showInsurancesBlock", false);
            break;
          default:
            console.log(`No matching keys`);
        }
        this.$store.commit(key, model);
        this.removeFilter(key);
        this.fetchResults();
      }
    },
    onFieldLostFocus(){
        this.$store.commit("CategorySearchResults", null);
    },
    onClearAll() {
      this.toggleAccepting = null;
      this.toggleVirtual = null;
      this.toggleOnline = null;
      this.checkedNextAvail = null;
      this.nameKeyword = '';
      this.insuranceKeyword = '';
      this.specialtyKeyword = '';
      this.checkedGenders = [];
      this.checkedTimes = [];
      this.checkedLang = [];
      this.$store.commit("mobileFilterActive", false);
      this.$store.commit("Sort", 'relevance,networks,availability_soonest,distance');
      this.$store.commit("showInsurancesBlock", false);
      this.$store.commit("clearAll", true);
      this.$store.dispatch('removeLocationCookie');
    },
    showFilterOptionsList(event) {
      let thisClicked = event.currentTarget;
      thisClicked.classList.toggle('expanded');
      let filterId = thisClicked.dataset.filterid;
      if(filterId){
        let parentElem = thisClicked.closest('.fields-group');
        if(parentElem){
          let checkboxes = parentElem.querySelectorAll('.input-square');
          if(checkboxes){
            if(thisClicked.classList.contains('expanded')){
              thisClicked.innerText = 'See Less';
              for(let chk of checkboxes){
                chk.classList.remove('hide');
              }
            }
            else{
              thisClicked.innerText = 'See More';
              for(let i in checkboxes){
                if(i > 4){
                  let chk = checkboxes[i];
                  chk.classList.add('hide');
                }
              }
            }
          }
        }
      }
    },
    toggleFilter(key, value) {
      this.addOrUpdateFilter({ key, value });
    },
    filterDropdown(value, input) {
      const lowerCaseValue = value.toLowerCase();
      const lowerCaseInput = input.toLowerCase();

      return !lowerCaseValue.includes(lowerCaseInput);
    },
    showNoResultsMessage(options, keyword) {
      return options.every(item => this.filterDropdown(item.value, keyword));
    },
    hideFilterGroup(event) {
      let thisClicked = event.currentTarget;
      thisClicked.classList.toggle('closed');
      let siblingElem = thisClicked.nextElementSibling;
      siblingElem.classList.toggle('hide');
    },
    closeMobileFilters(){
      this.$store.commit("mobileFilterActive", false);
    }
  },
  mounted() {
    this.selects = $('.js-select2-dropdown');
    if (this.selects.length) {
      // Collect unique select types on the page
      this.selects.each(function () {
        const selectType = $(this).data('select-type');
        if (this.selectTypes.indexOf(selectType) === -1)
          this.selectTypes.push(selectType);
      });

      const JSON_TYPES = new Set(['services', 'locations', 'providers'])
      this.selectTypes.forEach(type => {
        if (JSON_TYPES.has(type)) {
          JSONSearch.FetchJSON(type).then(response => this.InitializeSelectType(response.items, type))
        } else {
          this.InitializeSelectType([], type)
        }
      });

      // close multi-select dropdown after clicking "apply" button
      $(document).on('click', '.js-submit-select', function(){
        this.selects.select2('close');
      });

      this.DropdownBelow();
    }

    if(this.Specialties) {
      this.specialtyKeyword = this.Specialties;
      this.toggleFilter( "Specialties", this.specialtyKeyword );
    }
  },
  watch: {
    'filterRemoved': {
      immediate: true,
      handler(filterRemoved) {
        if (filterRemoved === true) {
          //Set static values = to the values in the Store
          this.toggleAccepting = this.AcceptingNewPatients;
          this.toggleVirtual = this.OffersVirtualVisits;
          this.toggleOnline = this.OffersOnlineScheduling;
          this.checkedNextAvail = this.NextAvailability;
          this.nameKeyword = this.ProviderName;
          this.insuranceKeyword = this.Insurances;
          this.specialtyKeyword = this.Specialties;
          this.checkedGenders = this.Genders;
          this.checkedTimes = this.PreferredTimes;
          this.checkedLang = this.Languages;
          this.$store.commit("filterRemoved", false);
        }
      },
    },
    'showInsurancesBlock': {
      immediate: true,
      handler(showInsurancesBlock) {
        if (showInsurancesBlock === true) {
          this.insuranceKeyword = '';
        }
      }
    },
    'clearAll': function(){
      const comp = this;
        if (this.clearAll === true) {
          this.onClearAll();
          //Set static values = to the values in the Store
          this.$store.commit("NextAvailability", null);
          this.$store.commit("AcceptingNewPatients", null);
          this.$store.commit("OffersOnlineScheduling", null);
          this.$store.commit("Genders", null);
          this.$store.commit("OffersVirtualVisits", null);
          this.$store.commit("PreferredTimes", null);
          this.$store.commit("Specialties", null);
          this.$store.commit("ProviderName", null);
          this.$store.commit("Insurances", null);
          this.$store.commit("Languages", null);
          this.$store.commit("Keyword", null);
          this.$store.commit("KeywordCategory", null);

          this.removeAllFilters();
          this.setUrlParams().then(function() {
            comp.fetchResults();
          });
          this.$store.commit("clearAll", false);
        }
    },
  }
};
</script>
