<template>
    <div>
        <div class="pre-results-callout">
            <slot name="visitCalloutContent"></slot>
            <!-- <button class="pre-results-callout__trigger" @click.prevent="showModal = true">
                {{ visitCalloutBtnText }}
            </button> -->
        </div>
        
        <div class="get-care-modal" v-if="showModal" @close="showModal = false">
            <div class="get-care-modal__backdrop" @click="showModal = false"></div>
            <div class="get-care-modal__content">
                <form :action="visitCalloutLink" method="GET" id="get-care-form">
                    <div class="get-care-modal__form-group">
                        <label for="mod-zip">Enter zip code</label>
                        <input @keyup="onSearchNear" @keydown.enter.prevent="onSearchNear" v-model="near_text" type="text" id="mod-zip" name="zip">
                        <button @click.prevent="onLocationClick" class="my-location"><i class="fa fa-crosshairs"></i> Use my current location</button>
                    </div>
                    <div class="get-care-modal__form-group">
                        <div role="radiogroup" aria-labelledby="mod-reason-label">
                            <label id="mod-reason-label">What is the main reason for your visit?</label>
                            <label v-for="(r, index) in reasonOptions.data" :key="index" class="get-care-modal__checkcontainer">
                                <input v-bind:value="r.id" type="radio" name="reason" v-model="reason_radio">
                                <div class="radiobtn"><span>{{ r.label }}</span></div>
                            </label>
                        </div>
                    </div>
                    <div class="get-care-modal__controls">
                        <button class="get-care-modal__cancel" @click="showModal = false">Cancel</button>
                        <input :disabled="reason_radio === '' && near_text === ''" type="submit" value="OK">
                    </div>
                    <input type="hidden" v-for="(value, key) in visitCalloutParams" v-bind:value="value" v-bind:name="key" :key="key" />
                </form>
            </div>
        </div>
    </div>
</template>

<script>
  import { mapActions, mapGetters } from "vuex";
  import _ from 'lodash';
  import { gmapApi } from "vue2-google-maps";

  export default {
    name: "VisitCallout",
    computed: {
      ...mapGetters(["Latitude", "Longitude", "theme"]),
      google: gmapApi,
      reasonOptions: function(){
        const filters = this.filters;
        if(filters != null) {
        const reasonsOpts = filters.filter(i => {
            return i.type === 'reasons'
        });
        return reasonsOpts[0];
        }
      },
      visitCalloutBtnText: function(){
        return this.visitCalloutBtn || 'Find Immediate Care';
      },
      visitCalloutParams: function(){
        let comp = this;
        let results = {};
        if(comp.visitCalloutLink){
          let urlParts = comp.visitCalloutLink.split('?');
          if(urlParts.length > 1){
            let params = new URLSearchParams(urlParts[1]);
            params.forEach(function(value, key){
                results[key] = value;
            })
            return results;
          }
        }
        else{
          return results;
        }
      }
    },
    data: () => {
      return {
        geocoderService: null,
          showModal: false,
          filters: null,
          near_text: '',
          reason_radio: ''
      }
    },
    methods: {
      ...mapActions(["fetchFilters", "updateFilters"]),
      onLocationClick() {
          this.$store.dispatch("setLocation");
        },
        onSearchNear: _.debounce(function (e) {
        if (this.near_text == "") {
            this.onClickClear();
        }
        if (e.target.value.length < 3) return;
        const value = e.target.value;
        this.geocoderService.geocode({ address: value }, (results, status) => {
            if (status === "OK") {
                const res = results[0];
                const lat = res.geometry.location.lat();
                const lng = res.geometry.location.lng();
                const address = res.formatted_address;

                this.$store.commit("Latitude", lat);
                this.$store.commit("Longitude", lng);
                this.near_text = address;
                this.fetchResults();
                this.updateFilters();
            }
        });
      }, 1500),
    },
    props: {
        visitCalloutBtn: String,
        visitCalloutLink: String
    },
    created() {
        this.$store.subscribe((mutation) => {
          if (mutation.type === "filters") {
            const filters = mutation.payload;
            this.filters = filters;
          }
        });

        this.$gmapApiPromiseLazy({}).then(() => {
          this.geocoderService = new this.google.maps.Geocoder();
        });

        this.$store.subscribe(mutation => {
          if (mutation.type === "location") {
            if (Object.keys(mutation.payload).length > 0) {
              this.near_text = `${mutation.payload.city}, ${mutation.payload.state} ${mutation.payload.postal}`;
            }
          }
        });
    }
  };
</script>