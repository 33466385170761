var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: _vm.rootClass }, [
    _c(
      "div",
      {
        staticClass: "collapsible-section",
        class: {
          active: _vm.mobileFilterActive === true,
          "collapsible-section": true
        },
        attrs: { id: "filter-sidebar" }
      },
      [
        _c(
          "div",
          {
            staticClass: "collapsible-section--content",
            attrs: { id: "collapsible-content" }
          },
          [
            _c(
              "div",
              { staticClass: "collapsible-section--content-wrapper" },
              [_c("DoctorSearchFilters")],
              1
            )
          ]
        ),
        _vm._v(" "),
        _vm._m(0)
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ul", { staticClass: "collapsible-section--tab-buttons" }, [
      _c("li", [
        _c(
          "button",
          {
            staticClass: "tab-button",
            attrs: {
              "aria-expanded": "false",
              "aria-controls": "collapsible-content"
            }
          },
          [
            _c("i", { staticClass: "fas fa-chevron-up" }),
            _vm._v(" "),
            _c("span", { staticClass: "tab-button--shrinked" }, [
              _vm._v("Filter Results")
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "tab-button--expanded" }, [
              _vm._v("Collapse")
            ])
          ]
        )
      ])
    ])
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-3b579d08", { render: render, staticRenderFns: staticRenderFns })
  }
}