<template>
  <div>
    <div class="search-filter" v-if="theme == 'default'">
        <h4>Search</h4>
      <input
        @keyup="onSearchChange"
        @keydown.enter.prevent="onSearchChange"
        type="text"
        max="30"
        v-model="search_text"
        placeholder="Enter keyword or location name"
      />
      <!-- <div class="result" v-if="location_string">
        <span>{{ location_string }}</span>
        <a href @click.prevent="onClickClear" class="clear">&times;</a>
      </div>-->
    </div>

    <div v-if="theme == 'minimal'">
      <div class="location__form-header form-row">
          <h5>Filter Results</h5>
          <a class="location__form-clear" href @click.prevent="onResetFilter">CLEAR ALL</a>
      </div>
      <div class="form-group">
          <label id="filterZipLabel" class="sr-only" for="filterZip">Zip code</label>
          <div class="input-group">
              <input 
                  id="filterZip" 
                  class="form-control" 
                  aria-label="Zip code" 
                  aria-describedby="filterZipLabel"
                  @keyup="onSearchNear"
                  @keydown.enter.prevent="onSearchNear"
                  v-model="near_text"
                  type="text"
                  maxlength="30"
                  placeholder="Zip Code"
              />
              <div class="input-group-append">
                  <button class="btn btn--primary" type="button" id="filterSubmit" @click.prevent="applyDistanceFilter"><span class="fas fa-angle-right"></span></button>
              </div>
          </div>
          <small class="form-helper"><a href @click.prevent="onLocationClick"><img :src="current_location_icon_url" alt="current location icon">Use My Current Location</a></small>

          <div class="result" v-if="location_string">
              <span>{{ location_string }}</span>
              <a href @click.prevent="onClickClear" class="clear">&times;</a>
          </div>
      </div>
    </div>

  </div>
</template>

<script>
import _ from "lodash";
import { mapActions, mapGetters } from "vuex";
import { gmapApi } from "vue2-google-maps";

export default {
  name: "SearchFilter",
  data() {
    return {
      location_string: null,
      search_text: "",
      near_text: "",
      geocoderService: null
    };
  },
  computed: {
    ...mapGetters(['theme', 'current_location_icon_url']),
    google: gmapApi
  },
  methods: {
    ...mapActions(["fetchResults", "fetchByText", "fetchFilters", "updateFilters"]),
    getZipFromUrl(filters) {
      const comp = this;
      const urlParams = new URLSearchParams(window.location.search);
      const zip = urlParams.get('zip');
      if(zip){
        comp.near_text = zip;
        comp.applyDistanceFilter();
      }
    },
    getKeywordFromUrl() {
      const comp = this;
      const urlParams = new URLSearchParams(window.location.search);
      const keyword = urlParams.get('keyword');
      if(keyword){
        comp.search_text = keyword;
        comp.onSearchChange();
      }
    },
    applyDistanceFilter(){
        const filterZip = document.getElementById('filterZip');
        if(filterZip){
          this.triggerEvent(filterZip, 'keyup');
        }
    },
    onClickClear() {
      this.location_string = null;
      this.$store.commit("Latitude", null);
      this.$store.commit("Longitude", null);
      this.$store.commit("location", {});
      this.fetchResults();
      this.updateFilters();
    },
    onResetFilter() {
      this.filters.forEach(filter => {
        const type = filter.type.charAt(0).toUpperCase() + filter.type.slice(1);
        filter.data.forEach(item => {
          if (item.checked) {
            item.checked = false;
          }
        });
        this.$store.commit(type, null);
        this.fetchResults();
      });
    },
    onSearchChange: _.debounce(function(e) {
      if (this.search_text == "") {
        this.$store.commit("Name", null);
        this.onClickClear();
      }
      if (this.search_text.length < 3) return;
      this.$store.commit("Name", this.search_text);
      this.fetchResults();
      this.updateFilters();
    }, 1500),
    onSearchNear: _.debounce(function(e) {
      if (this.near_text == "") {
        this.onClickClear();
      }

      if (e.target.value.length < 3) return;
      const value = e.target.value;
      this.geocoderService.geocode({ address: value }, (results, status) => {
        if (status === "OK") {
          const res = results[0];
          const lat = res.geometry.location.lat();
          const lng = res.geometry.location.lng();
          const address = res.formatted_address;

          this.$store.commit("Latitude", lat);
          this.$store.commit("Longitude", lng);
          this.near_text = address;
          this.fetchResults();
          this.updateFilters();
        }
      });
    }, 1500),
    onLocationClick() {
      this.$store.dispatch("setLocation");
    },
    triggerEvent(el, type){
      if ('createEvent' in document) {
            // modern browsers, IE9+
            var e = document.createEvent('HTMLEvents');
            e.initEvent(type, false, true);
            el.dispatchEvent(e);
        } else {
            // IE 8
            var e = document.createEventObject();
            e.eventType = type;
            el.fireEvent('on'+e.eventType, e);
        }
    }
  },
  created() {
    this.getKeywordFromUrl();
    this.$gmapApiPromiseLazy({}).then(() => {
      this.geocoderService = new this.google.maps.Geocoder();
      this.getZipFromUrl();
    });
    this.$store.subscribe(mutation => {
      if (mutation.type === "location") {
        if (Object.keys(mutation.payload).length > 0) {
          //   this.location_string = `${mutation.payload.city}, ${mutation.payload.state} ${mutation.payload.postal}`;
          this.near_text = `${mutation.payload.city}, ${mutation.payload.state} ${mutation.payload.postal}`;
          this.fetchResults();
          this.updateFilters();
        }
      }
      if (mutation.type === "filters") {
        const filters = mutation.payload;
        /*
        filters.forEach(filter => {
          filter.selected = [];
        });*/
        this.filters = filters;
      }
    });
  }
};
</script>