let settings = {};

function readConfigFile(isDev, buildConfigPath){
    return new Promise(resolve => {
        let configLocation = '/assets/json/fad-search.config.json';

        const response = fetch(configLocation)
        .then((response) => {
          return response.json();  
        })
        .then((data) => {
            resolve(data);
        });
    });
}

async function getConfig(currentEnv, buildConfigPath){
    settings = await readConfigFile(currentEnv !== 'production', buildConfigPath);
    return settings;
}

export default {
    load: getConfig
  };