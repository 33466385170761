var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.banner_only
      ? _c("div", [_c("KeyboardSearchHero")], 1)
      : _c(
          "div",
          [
            _c("KeyboardSearchHero"),
            _vm._v(" "),
            _c(
              "div",
              {
                class: { list: _vm.view == "list", map: _vm.view == "map" },
                attrs: { id: "locations-app" }
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "locations-content",
                    class: { map: _vm.view == "map" }
                  },
                  [
                    _c("transition", { attrs: { name: "fade" } }, [
                      _vm.resultsLoading
                        ? _c("div", { staticClass: "loading" }, [
                            _c("div", { staticClass: "loading-inner" }, [
                              _c("div", { staticClass: "spinner-container" }, [
                                _c(
                                  "svg",
                                  {
                                    attrs: {
                                      width: "210",
                                      height: "210",
                                      viewBox: "0 0 210 210",
                                      fill: "none",
                                      xmlns: "http://www.w3.org/2000/svg"
                                    }
                                  },
                                  [
                                    _c("path", {
                                      attrs: {
                                        "fill-rule": "evenodd",
                                        "clip-rule": "evenodd",
                                        d:
                                          "M105 210C162.99 210 210 162.99 210 105C210 47.0101 162.99 0 105 0C47.0101 0 0 47.0101 0 105C0 162.99 47.0101 210 105 210ZM105 173C142.555 173 173 142.555 173 105C173 67.4446 142.555 37 105 37C67.4446 37 37 67.4446 37 105C37 142.555 67.4446 173 105 173Z",
                                        fill: "url(#paint0_linear_1_8)"
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "defs",
                                      [
                                        _c(
                                          "linearGradient",
                                          {
                                            attrs: {
                                              id: "paint0_linear_1_8",
                                              x1: "210",
                                              y1: "105",
                                              x2: "0",
                                              y2: "105",
                                              gradientUnits: "userSpaceOnUse"
                                            }
                                          },
                                          [
                                            _c("stop", {
                                              attrs: { "stop-color": "#0072CE" }
                                            }),
                                            _vm._v(" "),
                                            _c("stop", {
                                              attrs: {
                                                offset: "1",
                                                "stop-color": "#009DDB"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                )
                              ]),
                              _vm._v(" "),
                              _c("span", [_vm._v("Updating Results")])
                            ])
                          ])
                        : _vm._e()
                    ]),
                    _vm._v(" "),
                    _c("Navbar"),
                    _vm._v(" "),
                    _c("div", [_c("CategoryList")], 1),
                    _vm._v(" "),
                    _vm.visitCalloutContent
                      ? _c(
                          "VisitCallout",
                          {
                            attrs: {
                              "visit-callout-btn": _vm.visitCalloutBtn,
                              "visit-callout-link": _vm.visitCalloutLink
                            }
                          },
                          [
                            _c("template", { slot: "visitCalloutContent" }, [
                              _c("div", {
                                domProps: {
                                  innerHTML: _vm._s(_vm.visitCalloutContentHtml)
                                }
                              })
                            ])
                          ],
                          2
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "locations-wrapper" },
                      [
                        _c(
                          "Sidebar",
                          [
                            _c("template", { slot: "postFilterContent" }, [
                              _c("div", {
                                domProps: {
                                  innerHTML: _vm._s(_vm.postFilterContentHtml)
                                }
                              })
                            ])
                          ],
                          2
                        ),
                        _vm._v(" "),
                        _vm.view == "map"
                          ? _c(
                              "div",
                              { staticClass: "map-container" },
                              [
                                _c("MapView", {
                                  attrs: {
                                    postFilterContentHtml: this
                                      .postFilterContentHtml
                                  }
                                })
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.view == "list"
                          ? _c(
                              "ProvidersListResult",
                              [
                                _c("template", { slot: "noResultContent" }, [
                                  _c("div", {
                                    domProps: {
                                      innerHTML: _vm._s(_vm.noResultContentHtml)
                                    }
                                  })
                                ])
                              ],
                              2
                            )
                          : _vm._e()
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.view == "list" ? _c("Paginator") : _vm._e()
                  ],
                  1
                )
              ]
            )
          ],
          1
        )
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-04c2046b", { render: render, staticRenderFns: staticRenderFns })
  }
}