var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "schedule-calendar__day" }, [
    _c("div", { staticClass: "schedule-calendar__header" }, [
      _c("span", { staticClass: "schedule-calendar__weekday" }, [
        _vm._v(_vm._s(_vm.weekday))
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "schedule-calendar__date" }, [
        _vm._v(_vm._s(_vm.month + " " + _vm.day))
      ])
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "schedule-calendar__hours" },
      [
        _vm._l(this.slotsToRender, function(item, index) {
          return _c("ScheduleSlot", {
            key: _vm.scheduleDay.Date + "-" + index,
            attrs: {
              scheduleSlot: item,
              scheduleBaseUrl: _vm.scheduleBaseUrl,
              providerId: _vm.providerId,
              locationId: _vm.locationId,
              isActive: true
            }
          })
        }),
        _vm._v(" "),
        _vm._l(_vm.emptySlots, function(index) {
          return _c("ScheduleSlot", {
            key: _vm.scheduleDay.Date + "-deactive-" + index,
            attrs: {
              scheduleBaseUrl: _vm.scheduleBaseUrl,
              providerId: _vm.providerId,
              locationId: _vm.locationId,
              isActive: false
            }
          })
        })
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-61e4c325", { render: render, staticRenderFns: staticRenderFns })
  }
}