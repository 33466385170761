var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "map-view-component" }, [
    _c("div", { class: _vm.mapClass, attrs: { id: "map" } }, [
      _c(
        "div",
        { class: _vm.gmapWrapperClass },
        [
          _c(
            "GmapMap",
            {
              ref: "locationMap",
              staticStyle: { width: "100%", height: "100%" },
              attrs: {
                center: { lat: 33.7676931, lng: -84.4906436 },
                zoom: _vm.mapZoom,
                "map-type-id": "roadmap"
              }
            },
            [
              _c(
                "GmapCluster",
                {
                  attrs: {
                    styles: _vm.clusterStyles,
                    clickable: true,
                    calculator: _vm.customClusterCalculator
                  },
                  on: { click: _vm.toggleClusterInfo }
                },
                [
                  _vm._l(_vm.mapItems, function(m, index) {
                    return _c(
                      "GmapMarker",
                      {
                        key: index,
                        ref: "`marker-${m.external_id}`",
                        refInFor: true,
                        attrs: {
                          title: m.external_id,
                          position: { lat: m.latitude, lng: m.longitude },
                          clickable: true,
                          draggable: false,
                          icon: _vm.getMarkerIcon(m)
                        },
                        on: {
                          click: function($event) {
                            _vm.center = { lat: m.latitude, lng: m.longitude }
                            _vm.onMarkerClick(m, m.external_id)
                          }
                        }
                      },
                      [
                        _c(
                          "GmapInfoWindow",
                          { attrs: { opened: _vm.opened_marker == m } },
                          [
                            !_vm.locationsLoading
                              ? _c(
                                  "div",
                                  [
                                    _c(
                                      "p",
                                      { staticClass: "gmap-info__title" },
                                      [_c("strong", [_vm._v(_vm._s(m.name))])]
                                    ),
                                    _vm._v(" "),
                                    _c("p", {
                                      staticClass: "gmap-info__address",
                                      domProps: { innerHTML: _vm._s(m.address) }
                                    }),
                                    _vm._v(" "),
                                    _vm._l(
                                      _vm.ProvidersByLocation.locations,
                                      function(location) {
                                        return _c(
                                          "div",
                                          {
                                            key: location.id,
                                            staticClass: "gmap-info__provider"
                                          },
                                          _vm._l(location.providers, function(
                                            provider
                                          ) {
                                            return _c(
                                              "li",
                                              { key: provider.id },
                                              [
                                                provider.imageUrl
                                                  ? _c("figure", [
                                                      _c("img", {
                                                        attrs: {
                                                          src: provider.imageUrl
                                                        }
                                                      })
                                                    ])
                                                  : _c(
                                                      "figure",
                                                      {
                                                        staticClass:
                                                          "c-provider-card__img-no-image"
                                                      },
                                                      [
                                                        _c(
                                                          "svg",
                                                          {
                                                            attrs: {
                                                              width: "105",
                                                              height: "110",
                                                              viewBox:
                                                                "0 0 105 110",
                                                              fill: "none",
                                                              xmlns:
                                                                "http://www.w3.org/2000/svg"
                                                            }
                                                          },
                                                          [
                                                            _c("path", {
                                                              attrs: {
                                                                "fill-rule":
                                                                  "evenodd",
                                                                "clip-rule":
                                                                  "evenodd",
                                                                d:
                                                                  "M17.5441 0C18.8927 0 19.986 1.02601 19.986 2.29167V7.17343C20.0086 7.29874 20.0204 7.42751 20.0204 7.55885C20.0204 7.6902 20.0086 7.81897 19.986 7.94428V12.8639C19.986 14.1295 18.8927 15.1555 17.5441 15.1555C16.1955 15.1555 15.1023 14.1295 15.1023 12.8639V9.85052H4.88372V36.1772C4.88372 50.4031 17.4484 62.0178 33.0562 62.0178C48.664 62.0178 61.2287 50.4031 61.2287 36.1772V9.85052H50.9155V12.8639C50.9155 14.1295 49.8223 15.1555 48.4737 15.1555C47.1251 15.1555 46.0318 14.1295 46.0318 12.8639V2.29167C46.0318 1.02601 47.1251 0 48.4737 0C49.8223 0 50.9155 1.02601 50.9155 2.29167V5.26719H63.6705C65.0191 5.26719 66.1124 6.2932 66.1124 7.55885V36.1772C66.1124 52.148 52.7693 65.1788 35.8706 66.4922V80.1785C35.8706 94.0381 48.3205 105.417 63.8711 105.417C79.4218 105.417 91.8716 94.0381 91.8716 80.1785V68.5479C87.0889 67.5231 83.5296 63.4923 83.5296 58.6911C83.5296 53.1176 88.3259 48.5824 94.2648 48.5824C100.204 48.5824 105 53.1176 105 58.6911C105 63.4597 101.489 67.4682 96.7554 68.5266V80.1785C96.7554 96.7281 81.9467 110 63.8711 110C45.7956 110 30.9869 96.7281 30.9869 80.1785V66.5423C13.7369 65.5601 0 52.382 0 36.1772V7.55885C0 6.2932 1.09326 5.26719 2.44186 5.26719H15.1023V2.29167C15.1023 1.02601 16.1955 0 17.5441 0ZM94.2648 53.1657C91.0431 53.1657 88.4133 55.6301 88.4133 58.6911C88.4133 61.752 91.0431 64.2165 94.2648 64.2165C97.4865 64.2165 100.116 61.752 100.116 58.6911C100.116 55.6301 97.4865 53.1657 94.2648 53.1657Z",
                                                                fill: "#EBE0EB"
                                                              }
                                                            })
                                                          ]
                                                        )
                                                      ]
                                                    ),
                                                _vm._v(" "),
                                                _c("span", [
                                                  _c(
                                                    "button",
                                                    {
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          $event.preventDefault()
                                                          return _vm.openModal(
                                                            provider
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(provider.name)
                                                      )
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  provider.specialties
                                                    ? _c("span", {
                                                        staticClass:
                                                          "item__specialties",
                                                        domProps: {
                                                          innerHTML: _vm._s(
                                                            _vm.specialtyLabel(
                                                              provider.specialties
                                                            )
                                                          )
                                                        }
                                                      })
                                                    : _vm._e()
                                                ])
                                              ]
                                            )
                                          }),
                                          0
                                        )
                                      }
                                    )
                                  ],
                                  2
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.locationsLoading
                              ? _c(
                                  "div",
                                  { staticClass: "loading-locations" },
                                  [
                                    _c("div", {
                                      staticClass: "spinner spinner--sm"
                                    }),
                                    _c("p", [_vm._v("Updating Results")])
                                  ]
                                )
                              : _vm._e()
                          ]
                        )
                      ],
                      1
                    )
                  }),
                  _vm._v(" "),
                  _c(
                    "GmapInfoWindow",
                    {
                      attrs: {
                        opened: _vm.opened_cluster,
                        position: _vm.infoWindowPosition
                      },
                      on: { closeclick: _vm.infoWindowClosed }
                    },
                    [
                      _vm._l(_vm.ProvidersByLocation.locations, function(
                        location
                      ) {
                        return !_vm.locationsLoading
                          ? _c(
                              "div",
                              {
                                key: location.id,
                                staticClass: "gmap-info__provider"
                              },
                              [
                                _c("p", { staticClass: "gmap-info__title" }, [
                                  _c("strong", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.getClusterLocationTitle(location.id)
                                      )
                                    )
                                  ])
                                ]),
                                _vm._v(" "),
                                _c("p", { staticClass: "gmap-info__address" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.getClusterLocationAddress(location.id)
                                    )
                                  )
                                ]),
                                _vm._v(" "),
                                _vm._l(location.providers, function(provider) {
                                  return _c("li", { key: provider.id }, [
                                    provider.imageUrl
                                      ? _c("figure", [
                                          _c("img", {
                                            attrs: { src: provider.imageUrl }
                                          })
                                        ])
                                      : _c(
                                          "figure",
                                          {
                                            staticClass:
                                              "c-provider-card__img-no-image"
                                          },
                                          [
                                            _c(
                                              "svg",
                                              {
                                                attrs: {
                                                  width: "105",
                                                  height: "110",
                                                  viewBox: "0 0 105 110",
                                                  fill: "none",
                                                  xmlns:
                                                    "http://www.w3.org/2000/svg"
                                                }
                                              },
                                              [
                                                _c("path", {
                                                  attrs: {
                                                    "fill-rule": "evenodd",
                                                    "clip-rule": "evenodd",
                                                    d:
                                                      "M17.5441 0C18.8927 0 19.986 1.02601 19.986 2.29167V7.17343C20.0086 7.29874 20.0204 7.42751 20.0204 7.55885C20.0204 7.6902 20.0086 7.81897 19.986 7.94428V12.8639C19.986 14.1295 18.8927 15.1555 17.5441 15.1555C16.1955 15.1555 15.1023 14.1295 15.1023 12.8639V9.85052H4.88372V36.1772C4.88372 50.4031 17.4484 62.0178 33.0562 62.0178C48.664 62.0178 61.2287 50.4031 61.2287 36.1772V9.85052H50.9155V12.8639C50.9155 14.1295 49.8223 15.1555 48.4737 15.1555C47.1251 15.1555 46.0318 14.1295 46.0318 12.8639V2.29167C46.0318 1.02601 47.1251 0 48.4737 0C49.8223 0 50.9155 1.02601 50.9155 2.29167V5.26719H63.6705C65.0191 5.26719 66.1124 6.2932 66.1124 7.55885V36.1772C66.1124 52.148 52.7693 65.1788 35.8706 66.4922V80.1785C35.8706 94.0381 48.3205 105.417 63.8711 105.417C79.4218 105.417 91.8716 94.0381 91.8716 80.1785V68.5479C87.0889 67.5231 83.5296 63.4923 83.5296 58.6911C83.5296 53.1176 88.3259 48.5824 94.2648 48.5824C100.204 48.5824 105 53.1176 105 58.6911C105 63.4597 101.489 67.4682 96.7554 68.5266V80.1785C96.7554 96.7281 81.9467 110 63.8711 110C45.7956 110 30.9869 96.7281 30.9869 80.1785V66.5423C13.7369 65.5601 0 52.382 0 36.1772V7.55885C0 6.2932 1.09326 5.26719 2.44186 5.26719H15.1023V2.29167C15.1023 1.02601 16.1955 0 17.5441 0ZM94.2648 53.1657C91.0431 53.1657 88.4133 55.6301 88.4133 58.6911C88.4133 61.752 91.0431 64.2165 94.2648 64.2165C97.4865 64.2165 100.116 61.752 100.116 58.6911C100.116 55.6301 97.4865 53.1657 94.2648 53.1657Z",
                                                    fill: "#EBE0EB"
                                                  }
                                                })
                                              ]
                                            )
                                          ]
                                        ),
                                    _vm._v(" "),
                                    _c("span", [
                                      _c(
                                        "button",
                                        {
                                          on: {
                                            click: function($event) {
                                              $event.preventDefault()
                                              return _vm.openModal(provider)
                                            }
                                          }
                                        },
                                        [_vm._v(_vm._s(provider.name))]
                                      ),
                                      _vm._v(" "),
                                      provider.specialties
                                        ? _c("span", {
                                            staticClass: "item__specialties",
                                            domProps: {
                                              innerHTML: _vm._s(
                                                _vm.specialtyLabel(
                                                  provider.specialties
                                                )
                                              )
                                            }
                                          })
                                        : _vm._e()
                                    ])
                                  ])
                                })
                              ],
                              2
                            )
                          : _vm._e()
                      }),
                      _vm._v(" "),
                      _vm.locationsLoading
                        ? _c("div", { staticClass: "loading-locations" }, [
                            _c("div", { staticClass: "spinner spinner--sm" }),
                            _c("p", [_vm._v("Updating Results")])
                          ])
                        : _vm._e()
                    ],
                    2
                  )
                ],
                2
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        [
          _c(
            "div",
            { staticClass: "cards" },
            [
              _vm._l(_vm.results.providers, function(result, index) {
                return _c(
                  "div",
                  [
                    _c("ProviderCardResult", {
                      key: result.id,
                      attrs: { doctor: result }
                    }),
                    _vm._v(" "),
                    index >= 0 && index < 1 ? _c("MyChristusBar") : _vm._e()
                  ],
                  1
                )
              }),
              _vm._v(" "),
              !_vm.results.providers
                ? _c("div", { staticClass: "no-results" }, [
                    _c("h4", { staticClass: "text-center" }, [
                      _vm._v("No doctors found")
                    ])
                  ])
                : _vm._e()
            ],
            2
          ),
          _vm._v(" "),
          _c("Paginator")
        ],
        1
      )
    ]),
    _vm._v(" "),
    _vm.modalOpen && _vm.modalContent
      ? _c("div", { staticClass: "modal-container" }, [
          _c("div", { staticClass: "modal-inner" }, [
            _c(
              "button",
              {
                staticClass: "modal-close",
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    _vm.modalOpen = false
                  }
                }
              },
              [
                _vm._v("\n        Close\n        "),
                _c(
                  "svg",
                  {
                    attrs: {
                      xmlns: "http://www.w3.org/2000/svg",
                      width: "17",
                      height: "17",
                      viewBox: "0 0 17 17",
                      fill: "none"
                    }
                  },
                  [
                    _c("g", { attrs: { id: "Vector" } }, [
                      _c("path", {
                        attrs: {
                          d:
                            "M8.80687 7.68102C9.25315 8.13406 9.25315 8.8698 8.80687 9.32284L1.95202 16.2815C1.50575 16.7346 0.780988 16.7346 0.334709 16.2815C-0.11157 15.8285 -0.11157 15.0928 0.334709 14.6397L6.38268 8.50012L0.33828 2.36051C-0.108 1.90747 -0.108 1.17173 0.33828 0.718687C0.784559 0.265646 1.50932 0.265646 1.95559 0.718687L8.81044 7.6774L8.80687 7.68102Z",
                          fill: "white"
                        }
                      }),
                      _vm._v(" "),
                      _c("path", {
                        attrs: {
                          d:
                            "M7.33471 9.31922C6.88844 8.86618 6.88844 8.13044 7.33471 7.67739L14.1896 0.718688C14.6358 0.265647 15.3606 0.265647 15.8069 0.718688C16.2532 1.17173 16.2532 1.90747 15.8069 2.36051L9.7589 8.50012L15.8033 14.6397C16.2496 15.0928 16.2496 15.8285 15.8033 16.2815C15.357 16.7346 14.6323 16.7346 14.186 16.2815L7.33114 9.32284L7.33471 9.31922Z",
                          fill: "white"
                        }
                      })
                    ])
                  ]
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "modal-window" },
              [_c("ProviderModal", { attrs: { doctor: _vm.modalContent } })],
              1
            )
          ])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-191581c1", { render: render, staticRenderFns: staticRenderFns })
  }
}