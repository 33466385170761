<template>
  <div class="field-set">
    <label @click.prevent="hideFilterGroup" for="fad-location-input"><span><b>City, State, or Zip Code</b></span></label>
    <div>
      <div class="field-set">
        <GmapAutocomplete ref="autocomplete" placeholder="" class="form__input filters__input filters__input--location js-fad-input" @place_changed='setPlace' :options="autocompleteOptions" @keydown="handleKeyDown" />
        <button @click.prevent="onClickClear" class="field-clear" v-if="(currentPlace != '' && currentPlace != null)">
          <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.80687 7.19313C9.25315 7.63941 9.25315 8.36416 8.80687 8.81044L1.95202 15.6653C1.50575 16.1116 0.780988 16.1116 0.334709 15.6653C-0.11157 15.219 -0.11157 14.4943 0.334709 14.048L6.38268 8L0.33828 1.95202C-0.108 1.50575 -0.108 0.780988 0.33828 0.334709C0.784559 -0.11157 1.50932 -0.11157 1.95559 0.334709L8.81044 7.18956L8.80687 7.19313Z" fill="#666666"/>
            <path d="M7.33471 8.80687C6.88844 8.36059 6.88844 7.63584 7.33471 7.18956L14.1896 0.33471C14.6358 -0.111569 15.3606 -0.111569 15.8069 0.33471C16.2532 0.780989 16.2532 1.50575 15.8069 1.95203L9.7589 8L15.8033 14.048C16.2496 14.4943 16.2496 15.219 15.8033 15.6653C15.357 16.1116 14.6323 16.1116 14.186 15.6653L7.33114 8.81044L7.33471 8.80687Z" fill="#666666"/>
          </svg>
        </button>
      </div>
      <button @click.prevent="onLocationClick" class="filters__geolocation-button js-geolocation-button">
        <svg width="7" height="7" viewBox="0 0 7 7" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M0.500036 2.93317C0.49914 2.98807 0.514952 3.04194 0.545374 3.08765C0.575797 3.13335 0.619393 3.16873 0.670383 3.18909L3.27054 4.22929L4.31077 6.82966C4.33086 6.87995 4.36558 6.92307 4.41043 6.95343C4.45528 6.9838 4.5082 7.00002 4.56236 7H4.5667C4.62155 6.99914 4.67484 6.98164 4.71953 6.94981C4.76421 6.91799 4.79818 6.87335 4.81694 6.8218L6.98351 0.863874C7.00124 0.815405 7.00476 0.762878 6.99364 0.712477C6.98252 0.662077 6.95723 0.615904 6.92075 0.579395C6.88427 0.542885 6.83812 0.517561 6.78772 0.506404C6.73733 0.495246 6.6848 0.498721 6.63632 0.516418L0.678237 2.68294C0.626663 2.70174 0.582013 2.73576 0.550193 2.78049C0.518373 2.82522 0.500883 2.87856 0.500036 2.93344V2.93317Z" fill="#2472CC"/>
        </svg>
        Use my current location
      </button>
      <span class="filters__geolocation-denied" v-if="locationDenied">
        <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M7.5 0.5C3.36 0.5 0 3.86 0 8C0 12.14 3.36 15.5 7.5 15.5C11.64 15.5 15 12.14 15 8C15 3.86 11.64 0.5 7.5 0.5ZM8.25 11.75H6.75V7.25H8.25V11.75ZM8.25 5.75H6.75V4.25H8.25V5.75Z" fill="black"/>
        </svg>
        It looks like you need to allow location permissions to use this feature. Please enable location permissions in your browser settings.
      </span>
      <div class="custom-select padding-left distance-travel">
        <label for="fad-filter-select-distance" class="label-sm">Within:</label>
        <div class="distance-filter">
          <div class="button-group">
            <button
              :disabled="!Latitude || !Longitude"
              :class="{'active': d.value == Distance}"
              :value="d.value"
              @click.prevent="onClickDistance"
              :key="d.value"
              v-for="d in distance_options"
            >{{ d.name }}</button>
          </div>
        </div>
        <div class="field-set show-travel">
          <div class="input-checkbox on-off">
            <input @change.prevent="onTravelTime" v-model="toggleTravelTime" :disabled="!Latitude || !Longitude" type="checkbox" id="travelTime" name="travelTime" />
            <label for="travelTime">Show Estimated Drive Time</label>
          </div>
        </div>
        <!-- <div class="input-checkbox on-off filter-tooltip-container">
          <input :disabled="!Latitude || !Longitude" type="checkbox" id="time" name="time" />
          <label for="time">Show Estimated Drive Time</label>
          <button @click.prevent="driveTimeTooltip = !driveTimeTooltip"><svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M7.5 0.5C3.36 0.5 0 3.86 0 8C0 12.14 3.36 15.5 7.5 15.5C11.64 15.5 15 12.14 15 8C15 3.86 11.64 0.5 7.5 0.5ZM8.25 11.75H6.75V7.25H8.25V11.75ZM8.25 5.75H6.75V4.25H8.25V5.75Z" fill="black"/>
          </svg></button>
          <div v-if="driveTimeTooltip" class="filter-tooltip"><p>Enter your zip code above and turn on toggle to see estimated drive time. Distance and estimated drive times  are calculated from the center of the entered zip code. </p></div>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import VueCookies from 'vue-cookies';
import { mapActions, mapMutations, mapGetters } from "vuex";
import { gmapApi } from "vue2-google-maps";

Vue.use(VueCookies);

export default {
  name: "DistanceFilter",
  data: () => {
    return {
      geocoderService: null,
      near_text: '',
      locationField : '',
      driveTimeTooltip: false,
      toggleTravelTime: null,
      siteLocCookie: '',
      currentPlace: null,
      autocomplete: null,
      locationDenied: false,
      distance_options: [
        { name: "5 miles", value: 5 },
        { name: "10 miles", value: 10 },
        { name: "15 miles", value: 15 },
        { name: "30 miles", value: 30 },
        { name: "50 miles", value: 50 }
      ],
      autocompleteOptions: {
        types: ['(regions)'],
        componentRestrictions: {
          country: [
            'us',
          ],
        },
      },
    };
  },
  computed: {
    ...mapGetters(["Latitude", "Longitude", "Distance", "LocationName", "distanceRemoved", "theme"]),
    google: gmapApi
  },
  created() {
    this.$gmapApiPromiseLazy({}).then(() => {
      this.geocoderService = new this.google.maps.Geocoder();
    });

    this.$store.subscribe(mutation => {
      if (mutation.type === "location") {
        if (Object.keys(mutation.payload).length > 0) {
          this.near_text = `${mutation.payload.city}, ${mutation.payload.state} ${mutation.payload.postal}`;
          //this.fetchResults();
          //this.updateFilters();
        }
      }
    });

    setTimeout(() => {
      this.getCookieVals();
    }, 500);
  },
  methods: {
    ...mapActions(["fetchResults", "updateActiveFilters"]),
    ...mapMutations(["addOrUpdateFilter", "removeFilter", "removeLocationCookie"]),
    applyDistanceFilter(){
      const filterZip = document.getElementById('filterZip');
      if(filterZip){
        this.triggerEvent(filterZip, 'keyup');
      }
    },
    setPlace(place) {
      this.locationDenied = false;
      this.currentPlace = place;
      const lat = this.currentPlace.geometry.location.lat();
      const lng = this.currentPlace.geometry.location.lng();
      const address = this.currentPlace.formatted_address;
      let locObj = {name: address, lat: lat, lng: lng, isCurrentLocation: false};
      this.$store.dispatch('setLocationCookie', locObj);

      this.$store.commit("Latitude", lat);
      this.$store.commit("Longitude", lng);
      this.$store.commit("Location", `${lat},${lng}`);
      this.$store.commit("Sort", 'networks,distance');
      this.toggleFilter("LocationName", address);
      this.fetchResults();
    },
    handleKeyDown(event) {
      if (event.key === 'Enter') {
        event.preventDefault();
        event.target.blur();
      }
    },
    getLocationFromUrl() {
      setTimeout(() => {
        const comp = this;
        const urlParams = new URLSearchParams(window.location.search);
        const location = urlParams.get('Location');
        if(location){
          const latlng = new google.maps.LatLng(this.Latitude, this.Longitude);
          this.geocoderService.geocode({ 'location': latlng }, (results, status) => {
            if (status === 'OK') {
              if (results[0]) {
                const addressComponents = results[0].address_components;
                let city, state;

                addressComponents.forEach(component => {
                  if (component.types.includes('locality')) {
                    city = component.long_name;
                  } else if (component.types.includes('administrative_area_level_1')) {
                    state = component.short_name;
                  }
                });

                const locationName = `${city}, ${state}`;
                this.$refs.autocomplete.$el.value = locationName;
                this.toggleFilter("LocationName", locationName);
              } else {
                console.error('No results found');
              }
            } else {
              console.error('Geocoder failed due to:', status);
            }
          });
        }
      }, 525);
    },
    getCookieVals() {
      const cookieValue = this.$cookies.get('christus-preferred-location');
      if (cookieValue) {
        const placeCookie = {
            formatted_address: cookieValue.name,
            geometry: {
                location: {
                    lat: () => cookieValue.location.lat,
                    lng: () => cookieValue.location.lng
                }
            }
        };
        this.$refs.autocomplete.$el.value = cookieValue.name;
        this.toggleFilter("LocationName", cookieValue.name);
      }
    },
    onClickClear(event){
      const urlParams = new URLSearchParams(window.location.search);
      urlParams.delete("Location");
      window.history.replaceState({}, "", `${location.pathname}?${urlParams}`);
      this.currentPlace = null;
      this.$refs.autocomplete.$el.value = '';
      this.$store.commit("Latitude", null);
      this.$store.commit("Longitude", null);
      this.$store.commit("Location", null);
      this.$store.commit("showTravelTime", null);
      this.toggleTravelTime = this.showTravelTime;
      this.removeFilter("LocationName");
      this.removeFilter("Distance");
      this.$store.commit("Sort", 'relevance,networks,availability_soonest,distance');
      this.$store.dispatch('removeLocationCookie');
      this.fetchResults();
    },
    onClickDistance(e) {
      const value = e.target.value || null;
      this.selected = value;
      this.$store.commit("Distance", this.selected);
      this.toggleFilter("Distance", this.selected);
      this.fetchResults();
    },
    onTravelTime(){
      this.$store.commit("showTravelTime", this.toggleTravelTime);
    },
    onLocationSet(){
      let locObj = {name: this.LocationName, lat: this.Latitude, lng: this.Longitude, isCurrentLocation: true};
      this.$store.dispatch('setLocationCookie', locObj);

      this.$store.commit("Sort", 'networks,distance');
      this.$refs.autocomplete.$el.value = this.LocationName;
      this.toggleFilter("LocationName", this.LocationName);
      this.fetchResults();
    },
    onLocationClick() {
      if (navigator.geolocation) {
        this.locationDenied = false;
        navigator.geolocation.getCurrentPosition(position => {
          const lat = position.coords.latitude;
          const lng = position.coords.longitude;
          this.$store.dispatch("setLocation", { lat: lat, lng: lng }).then(() => {
            this.onLocationSet()
          });
        }, error => {
          if (error.code === error.PERMISSION_DENIED) {
            console.error("User denied Geolocation access. You can prompt them to enter their location manually.");
            this.locationDenied = true;
          } else {
            console.error("Error getting location:", error.message);
            this.$store.dispatch("setLocationFallback").then(() => {
              this.onLocationSet()
            });
          }
        });
      } else {
        this.$store.dispatch("setLocationFallback").then(() => {
          this.onLocationSet()
        });
      }
    },
    hideFilterGroup(event) {
      let thisClicked = event.currentTarget;
      thisClicked.classList.toggle('closed');
      let siblingElem = thisClicked.nextElementSibling;
      siblingElem.classList.toggle('hide');
    },
    toggleFilter(key, value) {
      this.addOrUpdateFilter({ key, value });
    },
  },
  mounted() {
    this.$gmapApiPromiseLazy({}).then(() => {
      this.geocoderService = new this.google.maps.Geocoder();
      this.getLocationFromUrl();
    });
  },
  watch: {
    'filterRemoved': {
      immediate: true,
      handler(filterRemoved) {
        if (filterRemoved === true) {
          //Set static values = to the values in the Store
          this.$refs.autocomplete.$el.value = this,LocationName;
          this.$store.commit("filterRemoved", false);
        }
      },
    },
    'distanceRemoved': {
      immediate: true,
      handler(distanceRemoved) {
        if (distanceRemoved === true) {
          //Set static values = to the values in the Store
          this.locationDenied = false;
          this.currentPlace = null;
          this.$refs.autocomplete.$el.value = '';
          this.$store.commit("Latitude", null);
          this.$store.commit("Longitude", null);
          this.$store.commit("Location", null);
          this.$store.commit("showTravelTime", null);
          this.toggleTravelTime = this.showTravelTime;
          this.$store.commit("distanceRemoved", false);
        }
      },
    }
  }
};
</script>

