<template>
  <div>
    <div v-if="banner_only">
      <KeyboardSearchHero />
    </div>
    <div v-else>
      <KeyboardSearchHero />

      <div id="locations-app" :class="{ list : view == 'list', map : view == 'map' }">  

        <div class="locations-content" :class="{ map : view == 'map' }">

          <transition name="fade">
            <div v-if="resultsLoading" class="loading">
              <div class="loading-inner">
                <div class="spinner-container">
                  <svg width="210" height="210" viewBox="0 0 210 210" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M105 210C162.99 210 210 162.99 210 105C210 47.0101 162.99 0 105 0C47.0101 0 0 47.0101 0 105C0 162.99 47.0101 210 105 210ZM105 173C142.555 173 173 142.555 173 105C173 67.4446 142.555 37 105 37C67.4446 37 37 67.4446 37 105C37 142.555 67.4446 173 105 173Z" fill="url(#paint0_linear_1_8)"/>
                    <defs>
                    <linearGradient id="paint0_linear_1_8" x1="210" y1="105" x2="0" y2="105" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#0072CE"/>
                    <stop offset="1" stop-color="#009DDB"/>
                    </linearGradient>
                    </defs>
                  </svg>
                </div>
                <span>Updating Results</span>
              </div>
            </div>
          </transition>

          <Navbar />

          <div>
            <CategoryList />
          </div>

          <VisitCallout v-if="visitCalloutContent" :visit-callout-btn="visitCalloutBtn" :visit-callout-link="visitCalloutLink">
              <template slot="visitCalloutContent">
                <div v-html="visitCalloutContentHtml"></div>
              </template>
          </VisitCallout>

          <div class="locations-wrapper">
            <Sidebar>
              <template slot="postFilterContent">
                <div v-html="postFilterContentHtml"></div>
              </template>
            </Sidebar>
            <div class="map-container" v-if="view == 'map'">
              <MapView :postFilterContentHtml="this.postFilterContentHtml"/>
            </div>
            <ProvidersListResult v-if="view == 'list'">
              <template slot="noResultContent">
                <div v-html="noResultContentHtml"></div>
              </template>
            </ProvidersListResult>
          </div>
          
          <Paginator v-if="view == 'list'" />

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import VisitCallout from "./components/VisitCallout.vue";
import Navbar from "./components/Navbar.vue";
import MapView from "./components/MapView.vue";
import Paginator from "./components/Paginator.vue";
import Banner from "./components/Banner.vue";
import CategoryList from "./components/CategoryList.vue";
import ProvidersListResult from "./components/ProvidersListResult.vue";
import Sidebar from "./components/Sidebar.vue";
import KeyboardSearchHero from "./components/KeyboardSearchHero"
import Config from "./config/config";

export default {
  name: "App",
  data() {
    return {
      data: null,
      activeDoctors: null,
      taxonomy: null,
      locations: null,
      postFilterContentHtml: '',
      visitCalloutContentHtml: '',
      noResultContentHtml: '',
      resizeTimer: null,
      categoriesArr: null,
      filteredDoctors: null,
      specialtiesFilter: null,
      resultsCount: null,
      locationItems: null,
      appointments: null,
      providersList: null
    };
  },
  computed: {
    ...mapGetters([
      "view", 
      "theme", 
      "resultsLoading",
      "app_view_mode", 
      "specialties_filter",
      "specialties_key",
      "banner_only",
      "banner_homepage",
      "banner_button_title",
      "LocationName"
    ]),
    appTheme: function(){
      if(this.theme){
        return this.theme;
      }
      return 'default';
    },
    appViewMode: function () {
        if (this.app_view_mode) {
            return this.app_view_mode;
        }
        return '';
    },
  },
  props: {
    postFilterContent: String,
    visitCalloutContent: String,
    visitCalloutBtn: String,
    visitCalloutLink: String,
    noResultContent: String,
    title: String,
    tempDoctorsArr: Array
  },
  methods: {
    ...mapActions(['getParamsFromUrl', 'fetchResults']),
    ...mapMutations(['removeFilter', 'addOrUpdateFilter']),
    onResize() {
      clearTimeout(this.resizeTimer);
      this.resizeTimer = setTimeout(() => {
        const width = window.innerWidth;
        if (width <= 992) {
          this.$nextTick(() => {
            this.$store.commit("view", "list");
          });
        }
      }, 250);
    },
    updateFilter(key, value){
      this.addOrUpdateFilter({ key, value });
      //this.fetchResults();
    }
  },
  mounted() {
    window.addEventListener("resize", this.onResize);
    let comp = this;

    Config.load(`${process.env.NODE_ENV}`, `${process.env.BUILD_CONFIG_PATH}`).then(config => {
        this.config = config;
        this.$store.commit('api_key', config.google_maps_api_key);
        this.$store.commit('theme', config.theme ? config.theme : 'default');
        this.$store.commit('current_location_icon_url', config.current_location_icon_url);
        this.$store.commit('default_image_url', config.default_image_url);
    });

    $(document).ready(function () {

      // Clear location values from store when user clicks 'Clear Location' in top nav
      const locUpdate = document.querySelector('.js-update-preferred-location');
      if(locUpdate){
        locUpdate.addEventListener('click', e => {
          const elem = e.target;
          if(elem.classList.contains('js-clear-location')){
            comp.$store.commit('Longitude', null);
            comp.$store.commit('Latitude', null);
            comp.$store.commit('Location', []);
            comp.$store.dispatch('removeLocationCookie');

            comp.removeFilter('LocationName');
            comp.$store.commit("filterRemoved", true);
            comp.fetchResults();
          }
        });
      }

      // Update location values in store when user changes location in top nav
      var target = document.querySelector('.js-preferred-location-text');
      if(target){
        var observer = new MutationObserver(function(mutations) {
            var targetText = target.innerText ? target.innerText.toLowerCase() : '';
            var currentLoc = comp.LocationName ? comp.LocationName.toLowerCase() : '';

            if(targetText !== 'set my location' && targetText !== currentLoc){
              comp.updateFilter("LocationName", target.innerText);
            }
        });

        observer.observe(target, {
            attributes:    true,
            childList:     true,
            characterData: true
        });
      }
    });


    // this.fetchData();
  },
  created() {
    const urlParams = new URLSearchParams(window.location.search);
    this.getParamsFromUrl();
  },
  components: {
    VisitCallout,
    Navbar,
    MapView,
    //ListView,
    Paginator,
    Banner,
    CategoryList,
    ProvidersListResult,
    Sidebar,
    KeyboardSearchHero
  },
  destroyed() {
    window.removeEventListener("resize", this.onResize);
  },
  watch:{
    postFilterContent: function(value){
      if(value){
        this.postFilterContentHtml = atob(value);
      }
    },
    visitCalloutContent: function(value){
      if(value){
        this.visitCalloutContentHtml = atob(value);
      }
    },
    noResultContent: function(value){
      if(value){
        this.noResultContentHtml = atob(value);
      }
    }
  }
};
</script>
<style lang="scss">
@import "./scss/_vars";

#locations-app {
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  //temp
  max-width: 1440px;
  margin: auto auto 2.5rem;

  @media (max-width: $break) {
    padding: 0;
  }

  &.locations-app-availability {
    box-shadow: none;
    padding-top: 0;

    @media (max-width: $break) {
      display: block;
    }
  }

  &.list {
    gap: 25px;

    @media (max-width: $break) {
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  &.map {
    .sidebar {
      width: 0;
    }
  }

  .locations-content {
    flex: 1;
    width: 100%;
    position: relative;

    @media (max-width: $break) {
      background-color: #fff;
    }
    &.map {
      display: flex;
      flex-direction: column;
      .map-view-component {
        flex: 1;
      }
      .providers-list-result {
        max-width: 1049px;
      }
    }
  }

  .fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }
}
</style>
