export default {
    state: {
        filters: [],
    },
    mutations: {
        filters: (state, filters) => { state.filters = filters },
    },
    getters: {
        filters: (state) => state.filters,
    },
    actions: {
        async fetchFilters({ state, commit, rootState }) {
            commit('filters_loaded', true);
        },
        async updateFilters({ state, commit, rootState }) {
            const viewType = rootState.view;
            const data = {
                SearchText: rootState.results.SearchText ? rootState.results.SearchText : null,
                Reasons: rootState.results.Reasons ? rootState.results.Reasons : "0",
                Range: rootState.results.Range ? rootState.results.Range : null,
                Latitude: rootState.results.Latitude,
                Longitude: rootState.results.Longitude,
                PageSize: 1000
            };

            Object.keys(data).forEach(key => {
                if (!data[key]) {
                    delete data[key];
                }
            });

            const url = new URL(`${rootState.api_url}/api/filters/searchfilters`);
            url.search = new URLSearchParams(data);
            const response = await fetch(url)
                .then(response => response.json());

            response.forEach(item => {
                item.data.forEach(el => {
                    try {
                        el.color = el.color.indexOf('#') < 0 ? '#' + el.color : el.color;
                    } catch (error) {
                    }
                });
            });

            // Ensure that our selected filters stay selected after the update
            let originalFilters = this.state.filters.filters;
            originalFilters.forEach((filter, i) => {
                filter.data.forEach((item, j) => {
                    if (item.checked) {
                        if(response[i] && response[i].data && response[i].data[j]){
                            response[i].data[j].checked = true;
                        }
                    }
                });
            });

            commit('filters', response);
        }
    }
}