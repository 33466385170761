export default {
    state: {
        location: {},
    },
    mutations: {
        location: (state, location) => { state.location = location }
    },
    getters: {
        location: (state) => state.location
    },
    actions: {
        async setLocation({ commit }, { lat, lng }) {
            return new Promise(resolve => {
                const locationGeo = `${lat},${lng}`;
                commit('Latitude', lat);
                commit('Longitude', lng);
                commit('Location', locationGeo);
                const geocoder = new google.maps.Geocoder();
                const latlng = {
                    lat: parseFloat(lat),
                    lng: parseFloat(lng),
                };
                geocoder.geocode({ location: latlng }, (results, status) => {
                    if (status === "OK") {
                        if (results[0]) {
                            let firstResult = results[0];
                            let city = '';
                            let state = '';
                            let zip = '';
                            for (let comp of firstResult.address_components) {
                                if (comp.types && (comp.types.includes('locality') || comp.types.includes('sublocality_level_1'))) {
                                    city = comp.long_name;
                                }
                                if (comp.types && comp.types.includes('administrative_area_level_1')) {
                                    state = comp.short_name;
                                }
                                if (comp.types && comp.types.includes('postal_code')) {
                                    zip = comp.short_name;
                                }
                            }
        
                            if (city && state) {
                                let locText = `${city}, ${state} ${zip}`;
                                commit('LocationName', locText);
                                resolve();
                            }
                        } else {
                            console.log("Geocoder: No results found");
                        }
                    } else {
                        console.log("Geocoder failed due to: " + status);
                    }
                });
            });
        },
        async setLocationFallback({ commit }) {
            return new Promise((resolve, reject) => {
                try {
                    fetch('https://geolocation-db.com/json/')
                        .then(response => {
                            if (!response.ok) {
                                throw new Error(`HTTP error - status: ${response.status}`);
                            }
                            return response.json();
                        })
                        .then(response => {
                            commit('Latitude', response.latitude);
                            commit('Longitude', response.longitude);
        
                            const locationName = `${response.city}, ${response.state}`;
                            const locationGeo = `${response.latitude},${response.longitude}`;
                            commit('LocationName', locationName);
                            commit('Location', locationGeo);
                            resolve();
                        })
                        .catch(error => {
                            console.error('Error fetching location', error);
                            reject(error);
                        });
                } catch (error) {
                    console.error('Error fetching location', error);
                    reject(error);
                }
            });
        }
    }
}