export default {
    state: {
        SearchResults: null, // Store the search results
        SpecialtySearchResults: null,
        NameSearchResults: null,
        InsuranceSearchResults: null,
    },
    mutations: {
        SearchResults: (state, SearchResults) => { state.SearchResults = SearchResults },
        SpecialtySearchResults: (state, SpecialtySearchResults) => { state.SpecialtySearchResults =  SpecialtySearchResults },
        NameSearchResults: (state, NameSearchResults) => { state.NameSearchResults =  NameSearchResults },
        InsuranceSearchResults: (state, InsuranceSearchResults) => { state.InsuranceSearchResults =  InsuranceSearchResults }
    },
    actions: {
        async fetchSearchResults({ commit, rootState }, query) {
            try {

                const url = `${rootState.api_url}/api/Kyruus/Typeahead?keyword=${query}`;
                const requestOpts = {
                    method: "POST"
                }
                const response = await fetch(url, requestOpts);
                const data = await response.json();
                let orderedData = {};
                if(data){
                    // Use name property for specialties until Kyruus updates their highlight field properly
                    if(data.specialties){
                        data.specialties.forEach((specialty) => {
                            specialty.highlight = specialty.name;
                        });
                    }

                    if(data.primary_care){
                        data.primary_care.forEach((item) => {
                            item.highlight = item.name;
                        });
                    }

                    orderedData = {
                        "specialties": data.specialties ?? [],
                        "conditions_symptoms": data.conditions_symptoms ?? [],
                        "treatments_procedures": data.treatments_procedures ?? [],
                        "provider_name": data.provider_name ?? [],
                        "location_name": data.location_name ?? [],
                        ...data // Include the remaining properties
                      };
                }

                commit('SearchResults', orderedData);
            } catch (error) {
                console.error('Error fetching search results:', error);
            }
        },
        async fetchCatSearchResults({ commit, rootState }, queryParams) {
            try {
                const { param1, param2 } = queryParams;
                const url = `${rootState.api_url}/api/Kyruus/Typeahead?keyword=${param1}&categories=${param2}`;
                const requestOpts = {
                    method: "POST"
                }
                const response = await fetch(url, requestOpts);
                const data = await response.json();

                // Use name property for specialties until Kyruus updates their highlight field properly
                if(data && data.specialties){
                    data.specialties.forEach((specialty) => {
                        specialty.highlight = specialty.name;
                      });
                }

                // This is bad running out of time and will power. Refactor at some point.
                if(param2 == 'specialties'){
                    commit('SpecialtySearchResults', data);
                }

                if(param2 == 'provider_name'){
                    commit('NameSearchResults', data);
                }

                if(param2 == 'insurances'){
                    commit('InsuranceSearchResults', data);
                }
                
            } catch (error) {
                console.error('Error fetching search results:', error);
            }
        },
    },
}