<template>
  <div class="map-view-component">

    <div :class="mapClass" id="map">
      <div :class="gmapWrapperClass">
        <GmapMap
          :center="{lat:33.7676931, lng:-84.4906436}"
          :zoom="mapZoom"
          ref="locationMap"
          map-type-id="roadmap"
          style="width: 100%; height: 100%"
        >
          <GmapCluster
            @click="toggleClusterInfo"
            :styles="clusterStyles"
            :clickable="true"
            :calculator="customClusterCalculator"
          >
            <GmapMarker
              :key="index"
              v-for="(m, index) in mapItems"
              :title="m.external_id"
              ref="`marker-${m.external_id}`"
              :position="{lat:m.latitude, lng:m.longitude}"
              :clickable="true"
              :draggable="false"
              :icon="getMarkerIcon(m)"
              @click="center={lat:m.latitude, lng:m.longitude}; onMarkerClick(m, m.external_id)"
            >
              <GmapInfoWindow :opened="opened_marker == m">
                <div v-if="!locationsLoading">
                  <p class="gmap-info__title">
                    <strong>{{ m.name }}</strong>
                  </p>
                  <p class="gmap-info__address" v-html="m.address"></p>
                  <div class="gmap-info__provider" v-for="location in ProvidersByLocation.locations" :key="location.id">
                    <li v-for="provider in location.providers" :key="provider.id">
                      <figure v-if="provider.imageUrl"><img :src="provider.imageUrl" /></figure>
                      <figure v-else class="c-provider-card__img-no-image">
                          <svg width="105" height="110" viewBox="0 0 105 110" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path fill-rule="evenodd" clip-rule="evenodd" d="M17.5441 0C18.8927 0 19.986 1.02601 19.986 2.29167V7.17343C20.0086 7.29874 20.0204 7.42751 20.0204 7.55885C20.0204 7.6902 20.0086 7.81897 19.986 7.94428V12.8639C19.986 14.1295 18.8927 15.1555 17.5441 15.1555C16.1955 15.1555 15.1023 14.1295 15.1023 12.8639V9.85052H4.88372V36.1772C4.88372 50.4031 17.4484 62.0178 33.0562 62.0178C48.664 62.0178 61.2287 50.4031 61.2287 36.1772V9.85052H50.9155V12.8639C50.9155 14.1295 49.8223 15.1555 48.4737 15.1555C47.1251 15.1555 46.0318 14.1295 46.0318 12.8639V2.29167C46.0318 1.02601 47.1251 0 48.4737 0C49.8223 0 50.9155 1.02601 50.9155 2.29167V5.26719H63.6705C65.0191 5.26719 66.1124 6.2932 66.1124 7.55885V36.1772C66.1124 52.148 52.7693 65.1788 35.8706 66.4922V80.1785C35.8706 94.0381 48.3205 105.417 63.8711 105.417C79.4218 105.417 91.8716 94.0381 91.8716 80.1785V68.5479C87.0889 67.5231 83.5296 63.4923 83.5296 58.6911C83.5296 53.1176 88.3259 48.5824 94.2648 48.5824C100.204 48.5824 105 53.1176 105 58.6911C105 63.4597 101.489 67.4682 96.7554 68.5266V80.1785C96.7554 96.7281 81.9467 110 63.8711 110C45.7956 110 30.9869 96.7281 30.9869 80.1785V66.5423C13.7369 65.5601 0 52.382 0 36.1772V7.55885C0 6.2932 1.09326 5.26719 2.44186 5.26719H15.1023V2.29167C15.1023 1.02601 16.1955 0 17.5441 0ZM94.2648 53.1657C91.0431 53.1657 88.4133 55.6301 88.4133 58.6911C88.4133 61.752 91.0431 64.2165 94.2648 64.2165C97.4865 64.2165 100.116 61.752 100.116 58.6911C100.116 55.6301 97.4865 53.1657 94.2648 53.1657Z" fill="#EBE0EB"/>
                          </svg>
                        </figure>
                      <span><button @click.prevent="openModal(provider)">{{ provider.name }}</button>
                      <span v-if="provider.specialties" class="item__specialties" v-html="specialtyLabel(provider.specialties)"></span></span>
                    </li>
                  </div>
                </div>
                <div class="loading-locations" v-if="locationsLoading"><div class="spinner spinner--sm"></div><p>Updating Results</p></div>
              </GmapInfoWindow>
            </GmapMarker>
            <GmapInfoWindow :opened="opened_cluster" :position="infoWindowPosition" @closeclick="infoWindowClosed">
                <div v-if="!locationsLoading" class="gmap-info__provider" v-for="location in ProvidersByLocation.locations" :key="location.id">
                  <p class="gmap-info__title">
                    <strong>{{ getClusterLocationTitle(location.id) }}</strong>
                  </p>
                  <p class="gmap-info__address">{{ getClusterLocationAddress(location.id) }}</p>
                  <li v-for="provider in location.providers" :key="provider.id">
                    <figure v-if="provider.imageUrl"><img :src="provider.imageUrl" /></figure>
                    <figure v-else class="c-provider-card__img-no-image">
                        <svg width="105" height="110" viewBox="0 0 105 110" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M17.5441 0C18.8927 0 19.986 1.02601 19.986 2.29167V7.17343C20.0086 7.29874 20.0204 7.42751 20.0204 7.55885C20.0204 7.6902 20.0086 7.81897 19.986 7.94428V12.8639C19.986 14.1295 18.8927 15.1555 17.5441 15.1555C16.1955 15.1555 15.1023 14.1295 15.1023 12.8639V9.85052H4.88372V36.1772C4.88372 50.4031 17.4484 62.0178 33.0562 62.0178C48.664 62.0178 61.2287 50.4031 61.2287 36.1772V9.85052H50.9155V12.8639C50.9155 14.1295 49.8223 15.1555 48.4737 15.1555C47.1251 15.1555 46.0318 14.1295 46.0318 12.8639V2.29167C46.0318 1.02601 47.1251 0 48.4737 0C49.8223 0 50.9155 1.02601 50.9155 2.29167V5.26719H63.6705C65.0191 5.26719 66.1124 6.2932 66.1124 7.55885V36.1772C66.1124 52.148 52.7693 65.1788 35.8706 66.4922V80.1785C35.8706 94.0381 48.3205 105.417 63.8711 105.417C79.4218 105.417 91.8716 94.0381 91.8716 80.1785V68.5479C87.0889 67.5231 83.5296 63.4923 83.5296 58.6911C83.5296 53.1176 88.3259 48.5824 94.2648 48.5824C100.204 48.5824 105 53.1176 105 58.6911C105 63.4597 101.489 67.4682 96.7554 68.5266V80.1785C96.7554 96.7281 81.9467 110 63.8711 110C45.7956 110 30.9869 96.7281 30.9869 80.1785V66.5423C13.7369 65.5601 0 52.382 0 36.1772V7.55885C0 6.2932 1.09326 5.26719 2.44186 5.26719H15.1023V2.29167C15.1023 1.02601 16.1955 0 17.5441 0ZM94.2648 53.1657C91.0431 53.1657 88.4133 55.6301 88.4133 58.6911C88.4133 61.752 91.0431 64.2165 94.2648 64.2165C97.4865 64.2165 100.116 61.752 100.116 58.6911C100.116 55.6301 97.4865 53.1657 94.2648 53.1657Z" fill="#EBE0EB"/>
                        </svg>
                      </figure>
                    <span><button @click.prevent="openModal(provider)">{{ provider.name }}</button>
                    <span v-if="provider.specialties" class="item__specialties" v-html="specialtyLabel(provider.specialties)"></span></span>
                  </li>
                </div>
                <div class="loading-locations" v-if="locationsLoading"><div class="spinner spinner--sm"></div><p>Updating Results</p></div>
              </GmapInfoWindow>
          </GmapCluster>
        </GmapMap>
      </div>

      <div>
        <div class="cards">
          <div v-for="(result, index) in results.providers">
            <ProviderCardResult :key="result.id" :doctor="result" />
            <MyChristusBar v-if="index >= 0 && index < 1" />
          </div>
          <div v-if="!results.providers" class="no-results">
            <h4 class="text-center">No doctors found</h4>
          </div>
        </div>
        <Paginator />
      </div>

    </div>
    <div v-if="modalOpen && modalContent" class="modal-container">
      <div class="modal-inner">
        <button @click.prevent="modalOpen = false" class="modal-close">
          Close
          <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
            <g id="Vector">
              <path d="M8.80687 7.68102C9.25315 8.13406 9.25315 8.8698 8.80687 9.32284L1.95202 16.2815C1.50575 16.7346 0.780988 16.7346 0.334709 16.2815C-0.11157 15.8285 -0.11157 15.0928 0.334709 14.6397L6.38268 8.50012L0.33828 2.36051C-0.108 1.90747 -0.108 1.17173 0.33828 0.718687C0.784559 0.265646 1.50932 0.265646 1.95559 0.718687L8.81044 7.6774L8.80687 7.68102Z" fill="white"/>
              <path d="M7.33471 9.31922C6.88844 8.86618 6.88844 8.13044 7.33471 7.67739L14.1896 0.718688C14.6358 0.265647 15.3606 0.265647 15.8069 0.718688C16.2532 1.17173 16.2532 1.90747 15.8069 2.36051L9.7589 8.50012L15.8033 14.6397C16.2496 15.0928 16.2496 15.8285 15.8033 16.2815C15.357 16.7346 14.6323 16.7346 14.186 16.2815L7.33114 9.32284L7.33471 9.31922Z" fill="white"/>
            </g>
          </svg>
        </button>
        <div class="modal-window">
          <ProviderModal :doctor="modalContent" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProviderCardResult from "./ProviderCardResult.vue";
import ProviderModal from "./ProviderModal.vue";
import Paginator from "./Paginator.vue";
import MyChristusBar from "./MyChristusBar.vue";
import GmapCluster from 'vue2-google-maps/src/components/cluster'
import { mapActions, mapGetters } from "vuex";

export default {
  name: "MapView",
  props: {
    postFilterContentHtml: String
  },
  data: function() {
    return {
      opened_marker: null,
      opened_cluster: null,
      current_marker: null,
      current_cluster: null,
      openInfoWindow: null,
      clusterStyles: [
        {
          textColor: 'white',
          textSize: 16,
          height: 66,
          width: 66,
          anchorText: [8, 0],
          url: 'assets/images/location-cluster.svg'
        },
      ],
      mapClass: null,
      mapZoom: this.mapZoom,
      mapItems: null,
      infoWindowPosition: { lat: 0, lng: 0 },
      modalOpen: false,
      modalContent: Object,
    };
  },
  methods: {
    ...mapActions(["fetchResults", "fetchProvidersbyLocations", "fetchClusterCount"]),
    onMarkerClick(marker, location) {
      this.opened_marker = marker;
      this.current_marker = marker;
      this.opened_cluster = null;
      this.fetchProvidersbyLocations(location);
    },
    updateMap() {
      if (this.$refs.locationMap) {
        let b = new window.google.maps.LatLngBounds();
          
        if(this.mapItems.length > 0){
          this.mapItems.forEach(el => {
            b.extend({lat:el.latitude, lng:el.longitude});
          });
          this.$refs.locationMap.$mapPromise.then(() => {
            this.$refs.locationMap.fitBounds(b);
          });
        } 
      }
    },
    customClusterCalculator(markers, numStyles) {
      // Customize this function to create a custom renderer
      const index = Math.min(numStyles, markers.length);
	  
      return {
        text: markers.length,
        index: index,
        title: `${markers.length} markers`,
      };
    },
    getMarkerIcon(marker) {
      const svg = `
      <svg width="46" height="66" viewBox="0 0 46 66" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M22.9989 63.4016L22.6416 63.7514L22.9989 64.1163L23.3562 63.7514L22.9989 63.4016ZM22.9989 63.4016C23.3562 63.7514 23.3563 63.7512 23.3566 63.751L23.3574 63.7501L23.3606 63.7468L23.3726 63.7345L23.419 63.6866C23.4598 63.6444 23.5199 63.582 23.598 63.5C23.7543 63.336 23.9829 63.0939 24.2734 62.7798C24.8544 62.1518 25.6834 61.2361 26.6776 60.0825C28.6657 57.7758 31.3171 54.5155 33.9694 50.7013C39.2557 43.0994 44.6189 33.1958 44.6189 24.2301C44.6189 11.4896 34.9712 1.10156 22.9989 1.10156C11.0266 1.10156 1.37891 11.4896 1.37891 24.2301C1.37891 33.1958 6.74211 43.0994 12.0284 50.7013C14.6808 54.5155 17.3321 57.7758 19.3202 60.0825C20.3144 61.2361 21.1434 62.1518 21.7244 62.7798C22.0149 63.0939 22.2435 63.336 22.3998 63.5C22.4779 63.582 22.538 63.6444 22.5788 63.6866L22.6252 63.7345L22.6372 63.7468L22.6404 63.7501L22.6413 63.751C22.6415 63.7512 22.6416 63.7514 22.9989 63.4016Z" fill="#0072CE" stroke="#1D5FA8"/>
        <circle cx="23" cy="23" r="8" fill="white"/>
      </svg>`;

      const url = "data:image/svg+xml;charset=UTF-8;base64," + btoa(svg);

      // const svgActive = `
      // <svg width="46" height="66" viewBox="0 0 46 66" fill="none" xmlns="http://www.w3.org/2000/svg">
      //   <path d="M22.9989 63.4016L22.6416 63.7514L22.9989 64.1163L23.3562 63.7514L22.9989 63.4016ZM22.9989 63.4016C23.3562 63.7514 23.3563 63.7512 23.3566 63.751L23.3574 63.7501L23.3606 63.7468L23.3726 63.7345L23.419 63.6866C23.4598 63.6444 23.5199 63.582 23.598 63.5C23.7543 63.336 23.9829 63.0939 24.2734 62.7798C24.8544 62.1518 25.6834 61.2361 26.6776 60.0825C28.6657 57.7758 31.3171 54.5155 33.9694 50.7013C39.2557 43.0994 44.6189 33.1958 44.6189 24.2301C44.6189 11.4896 34.9712 1.10156 22.9989 1.10156C11.0266 1.10156 1.37891 11.4896 1.37891 24.2301C1.37891 33.1958 6.74211 43.0994 12.0284 50.7013C14.6808 54.5155 17.3321 57.7758 19.3202 60.0825C20.3144 61.2361 21.1434 62.1518 21.7244 62.7798C22.0149 63.0939 22.2435 63.336 22.3998 63.5C22.4779 63.582 22.538 63.6444 22.5788 63.6866L22.6252 63.7345L22.6372 63.7468L22.6404 63.7501L22.6413 63.751C22.6415 63.7512 22.6416 63.7514 22.9989 63.4016Z" fill="#671E75" stroke="#470A68"/>
      //   <circle cx="23" cy="23" r="8" fill="white"/>
      // </svg>`;

      // const urlActive = "data:image/svg+xml;charset=UTF-8;base64," + btoa(svgActive);

      // if (this.current_marker === marker) return urlActive;
      return url;
    },
    specialtyLabel(specialties) {
        return specialties && specialties.length > 0 ? specialties.map(item => `<span>${item}</span>`).join(', ') : '';
    },
    getClusterLocationTitle(id){
      for (var i = 0; i < this.mapItems.length; i++) {
        if (this.mapItems[i].external_id === id) {
          return this.mapItems[i].name;
        }
      }
      return null; // Return null if the title is not found
    },
    getClusterLocationAddress(id){
      for (var i = 0; i < this.mapItems.length; i++) {
        if (this.mapItems[i].external_id === id) {
          return this.mapItems[i].address;
        }
      }
      return null; // Return null if the title is not found
    },
    findSitecoreIdByTitle(title) {
      for (var i = 0; i < this.mapItems.length; i++) {
        if (this.mapItems[i].name === title) {
          return this.mapItems[i].external_id;
        }
      }
      return null; // Return null if the title is not found
    },
    toggleClusterInfo(cluster) {
      this.opened_cluster = true;
      this.current_cluster = cluster
      const clusterMarkers = cluster.getMarkers();
      const sitecoreIds = [];
      this.opened_marker = null;

      for (var i = 0; i < clusterMarkers.length; i++) {
        var marker = clusterMarkers[i];
        var id = marker.getTitle();

        if (id) {
          sitecoreIds.push(id);
        }
      }

      const uniqueSitecoreIdsArray = [...new Set(sitecoreIds.filter(str => str.trim() !== ''))];
      const uniqueSitecoreIds = uniqueSitecoreIdsArray.join(',');
      this.fetchProvidersbyLocations(uniqueSitecoreIds);
      this.infoWindowPosition = clusterMarkers[0].getPosition();
    },
    infoWindowClosed() {
      this.opened_cluster = null;
      this.current_cluster = null;
    },
    getTotalProvidersCount(data) {
      let totalCount = 0;

      // Iterate through the main array
      for (const obj of data) {
        // Check if the object has a 'providers' array
        if (obj.providers && Array.isArray(obj.providers)) {
          // Add the length of the 'providers' array to the total count
          totalCount += obj.providers.length;
        }
      }

      return totalCount;
    },
    returnCount(markers){
      const sitecoreIds = [];
      const comp = this;

      for (var i = 0; i < markers.length; i++) {
        var marker = markers[i];
        var id = marker.getTitle();
		
        if (id) {
          sitecoreIds.push(id);
		}
      }

      const commaSeparatedSitecoreIds = sitecoreIds.join(',');
      const trimmedIDs = commaSeparatedSitecoreIds.split(',').map(id => id.trim());
      const uniqueSitecoreIdsArray = [...new Set(trimmedIDs)];
      const uniqueSitecoreIds = uniqueSitecoreIdsArray.join(',');

      async function asyncCall(ids) {
        const data = await comp.fetchClusterCount(ids);
        const docCount = comp.getTotalProvidersCount(data.locations);
        const label = docCount.toString();
        return label;
      };

      const myConstPromise = Promise.resolve(asyncCall(uniqueSitecoreIds));

      const l = myConstPromise.then(result => {
        return result;
      }).catch(error => {
        console.error('Error in myConstPromise:', error);
      });

      return l;
    },
    openModal(content) {
      this.modalContent = content;
      this.modalOpen = true;
    }
  },
  computed: {
    ...mapGetters(["results", "locationsLoading", "ProvidersByLocation", "theme", "app_view_mode"]),
    gmapWrapperClass: function(){
      let wrapperClass = 'map';
      if(this.theme == 'minimal'){
        wrapperClass = ''
      }

      return wrapperClass;
    },
  },
  created() {
    this.mapClass = 'map-view';
    // temp - get url params for style testing : end

    if(this.theme == 'minimal'){
      this.mapClass = 'map location__map';
    }

    this.$store.subscribe(mutation => {
      if (mutation.type === "results") {
        let items = mutation.payload.providers;
        if (!items) return;
        items = items.map(item => item.locations);
        const allLocations = [].concat(...items);
		this.mapItems = [...new Map(allLocations.map(item => [item["external_id"], item])).values()];
        this.updateMap();
      }

      if (mutation.type === "location") {
        if (Object.keys(mutation.payload).length > 0) {
          this.current_marker = {
            title: mutation.payload.name,
            color: mutation.payload.color,
            position: {
              lat: mutation.payload.latitude,
              lng: mutation.payload.longitude
            },
          };
          if (this.$refs.locationMap) {
            this.$refs.locationMap.$mapPromise.then(map => {
              map.panTo({
                lat: mutation.payload.latitude,
                lng: mutation.payload.longitude
              });
              this.mapZoom = 14;
            });
            this.updateMap();
          }
        } else {
          this.current_marker = null;
          this.updateMap();
        }
      }
    });
  },
  components: {
    ProviderCardResult,
    ProviderModal,
    MyChristusBar,
    Paginator,
    GmapCluster
  },
  destroyed() {
    this.$refs.locationMap = null;
  }
};
</script>
